import React from 'react';
import { Link } from 'react-router-dom';
import "../Contact/Contact.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaEnvelope } from 'react-icons/fa';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';
import ContactForm from '../../components/ContactForm/ContactForm';

function Contact() {
    const handleCall = () => {
        window.location.href = "tel: +19528482469";
    };

    const handleEmail = () => {
        window.location.href = "mailto:nreteam@nevinrealestate.com";
    };

    return (
        <>
            <NavbarBlack />
            <div className="contact-page-container">
                <div className="contact-page-left-div">
                    <p className="contact-page-connect">Let's Connect</p>
                    <ContactForm />
                </div>
                <div className="contact-page-right-div">
                    <p className="contact-page-name">Nevin Real Estate</p>
                    <p className="contact-page-subheader">Contact Information</p>
                    <div className="contact-page-contact-info-phone-license">
                        <div className="cpcipl-left">
                            <p className="contact-page-label">
                                Phone
                            </p>
                            <p className="contact-page-contact-info-text">
                                (952) 848-2469
                            </p>
                        </div>
                        <div className="cpcipl-right">
                            <p className="contact-page-label">
                                Email
                            </p>
                            <p className="contact-page-contact-info-text email-full">
                                NRETeam@nevinrealestate.com
                            </p>
                            <p className="contact-page-contact-info-text email-p-t">NRETeam@Nevin</p>
                            <p className="contact-page-contact-info-text email-p-b">RealEstate.com</p>
                        </div>
                    </div>
                    <p className="contact-page-label">
                        Address
                    </p>
                    <p className="contact-page-address">
                        3535 Plymouth Boulevard, Suite 210
                    </p>
                    <p className="contact-page-address">
                        Plymouth, MN 55447
                    </p>
                    <div className="contact-page-social">
                        <div className="contact-page-social-wrapper">
                            <Link to="http://www.facebook.com/nevinrealestate" className="contact-page-social-link">
                                <FaFacebook className="contact-page-social-icon" />
                            </Link>
                        </div>
                        <div className="contact-page-social-wrapper">
                            <Link to="http://www.instagram.com/nevinrealestate" className="contact-page-social-link">
                                <FaInstagram className="contact-page-social-icon" />
                            </Link>
                        </div>
                        <div className="contact-page-social-wrapper">
                            <Link to="http://www.linkedin.com" className="contact-page-social-link">
                                <FaLinkedin className="contact-page-social-icon" />
                            </Link>
                        </div>
                        <div className="contact-page-social-wrapper" onClick={handleCall}>
                            <FaPhone className="contact-page-social-icon" />
                        </div>
                        <div className="contact-page-social-wrapper" onClick={handleEmail}>
                            <FaEnvelope className="contact-page-social-icon" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact
