import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../HT/HT.css';

function HT() {
    const htAnimations = () => {
        const htContainer = document.querySelector('.ht-container');
        const htRight = document.querySelector('.ht-h1');
        const htLeft = document.querySelector('.ht-left');
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY;
        const htContainerTop = htContainer.getBoundingClientRect().top;

        if (scrollY + windowHeight > htContainerTop + 200) {
            htLeft.classList.add('slide-in');
        } else {
            htLeft.classList.remove('slide-in');
        }

        if (scrollY + windowHeight > htContainerTop + 100) {
            htRight.classList.add('slide-down');
        } else {
            htRight.classList.remove('slide-down');
        };
    };

    useEffect(() => {
        window.addEventListener('scroll', htAnimations);

        return () => {
            window.removeEventListener('scroll', htAnimations);
        };
    }, []);

    const [slideIndex, setSlideIndex] = useState(1);

    let slideTimer;

    useEffect(() => {
        showSlides(slideIndex);
        startSlideTimer();
        return () => clearTimeout(slideTimer);
    }, [slideIndex]);

    function plusSlides(n) {
        setSlideIndex(prevIndex => prevIndex + n);
        resetSlideTimer();
    };

    function showSlides(n) {
        const slides = document.getElementsByClassName("testimonial-slide");
        let i;
        if (slides.length === 0) return;

        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "block";
    };

    function startSlideTimer() {
        slideTimer = setTimeout(() => {
            plusSlides(1);
        }, 7000);
    };

    function resetSlideTimer() {
        clearTimeout(slideTimer);
        startSlideTimer();
    };

    return (
        <div className="ht-container">
            <div className="ht-testimonial-div">
                <div className="ht-left-right-container">
                    <div className="ht-left">
                        <span className="ht-left-span">&ldquo;</span>
                    </div>
                    <div className="ht-right">
                        <p className="ht-h1">
                            Testimonials
                        </p>
                        <div className="ht-slideshow">
                            <div className="testimonial-slide">
                                <p className="ht-text-p">
                                    "Nevin and team helped us manage through what currently is a frustrating and confusing housing market. They offered up to date market data, helped us get home ready to show and supported us through a new and challenging market. Advice was clear data driven and resulted in a sale that worked for all. Highly recommend."
                                </p>
                                <p className="ht-name">&mdash; Seller</p>
                            </div>
                            <div className="testimonial-slide">
                                <p className="ht-text-p">
                                    "Thank you Nevin. You and your team made buying a house easy and have exceeded all our expectations."
                                </p>
                                <p className="ht-name">&mdash; Buyer</p>
                            </div>
                            <div className="testimonial-slide">
                                <p className="ht-text-p">
                                    "My husband and I had the pleasure of working with Nevin and his team for our new home purchase (and prior home sale). The entire team provided exceptional service and navigated us through the complexities of the process with agility and expertise."
                                </p>
                                <p className="ht-name">&mdash; Buyer</p>
                            </div>
                            <div className="testimonial-slide">
                                <p className="ht-text-p">
                                    "Nevin and his team were the best to work with. They had my family's concerns and demands in mind every step of the way. They were very in tune with the housing market which benefited us when we sold our house and then again when we bought our new house. They are my new realtor team from here on out. Thank you Nevin and your team."
                                </p>
                                <p className="ht-name">&mdash; Buyer & Seller</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ht-bottom-div">
                    <Link to="/testimonials" className="ht-button">View All</Link>
                </div>
            </div>
        </div>
    )
}

export default HT
