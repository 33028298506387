import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';
import "../Buying/BuyerQuestionnaire.css";
import { FaTimes } from 'react-icons/fa';
import BQ from '../../components/BQ/BQ';

function BuyerQuestionnaire() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        spouseFirstName: '',
        spouseLastName: '',
        homePhone: '',
        cellPhone: '',
        spousePhone: '',
        email: '',
        spouseEmail: '',
        contactMethod: '',
        priceRange: '',
        preapproval: '',
        downPayment: '',
        monthlyPaymentLimit: '',
        beds: '',
        baths: '',
        garageSpaces: '',
        homeAge: '',
        neighborhood: '',
        moveInGoal: '',
        timeInNextHome: '',
        investment: '',
        specialCircumstances: '',
        isBuildingAnOption: '',
        specificRequests: ''
    });

    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.firstName || !formData.lastName || !formData.cellPhone || !formData.email) {
            alert('Please fill out all required fields.');
            return;
        }

        try {
            await axios.post('https://82.180.133.122/api/buyers', formData);
            setFormData({
                firstName: '',
                lastName: '',
                spouseFirstName: '',
                spouseLastName: '',
                homePhone: '',
                cellPhone: '',
                spousePhone: '',
                email: '',
                spouseEmail: '',
                contactMethod: '',
                priceRange: '',
                preapproval: '',
                downPayment: '',
                monthlyPaymentLimit: '',
                beds: '',
                baths: '',
                garageSpaces: '',
                homeAge: '',
                neighborhood: '',
                moveInGoal: '',
                timeInNextHome: '',
                investment: '',
                specialCircumstances: '',
                isBuildingAnOption: '',
                specificRequests: ''
            });
            setSuccess(true);
        } catch (err) {
            console.error(err);
            alert('Something went wrong');
        }
    };

    const handleCloseSuccess = () => {
        setSuccess(false);
    };

    return (
        <>
            <NavbarBlack />
            <div className="questionnaire-page-container">
                <h1 className="headline-h1">BUYER QUESTIONNAIRE</h1>
                <p className="questionnaire-subheadline">The first step on your home buying journey</p>
                <div className="questionnaire-page-img-wrapper">
                    <img src="/luxury-img.jpg" className="questionnaire-page-img" alt="Luxury Home Interior" />
                </div>
                <div className="main-form-container">
                    <BQ className="bq" />
                    {/* {success ? (
                        <div className="submission-success-message">
                            <p className="ssm-p">Thank you for submitting the questionnaire.</p>
                            <p className="ssm-p">A member of our team will be in contact shortly.</p>
                            <div className="rtn-home-button-container">
                                <Link to="/" className="rtn-home-button">Return Home</Link>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="questionnaire-intro">
                                <p className="qi-p">In order for us to better and more accurately serve you, please fill out our personalized buyer questionnaire below.</p>
                                <p className="qi-p">
                                    We will be in contact shortly after receiving your form.
                                </p>
                            </div>
                            <div className="asterisk-means">Fields marked with a <span className="required-label">*</span> are required.</div>
                            <form onSubmit={handleSubmit}>
                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="firstName" className="questionnaire-label">First Name:<span className="required-label">*</span></label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            className="questionnaire-input-field-sm"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            required />
                                    </div>
                                </div>
                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="lastName" className="questionnaire-label">Last Name:<span className="required-label">*</span></label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            className="questionnaire-input-field-sm"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="spouseFirstName" className="questionnaire-label">What is your husband's / wife's first name?</label>
                                        <input
                                            type="text"
                                            id="spouseFirstName"
                                            name="spouseFirstName"
                                            className="questionnaire-input-field-sm"
                                            value={formData.spouseFirstName}
                                            onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="spouseLastName" className="questionnaire-label">What is your husband's / wife's last name?</label>
                                        <input
                                            type="text"
                                            id="spouseLastName"
                                            name="spouseLastName"
                                            className="questionnaire-input-field-sm"
                                            value={formData.spouseLastName}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="homePhone" className="questionnaire-label">What is your HOME phone number?</label>
                                        <input
                                            type="number"
                                            id="homePhone"
                                            name="homePhone"
                                            className="questionnaire-input-field-sm"
                                            value={formData.homePhone}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="cellPhone" className="questionnaire-label">What is your CELL phone number?<span className="required-label">*</span></label>
                                        <input
                                            type="number"
                                            id="cellPhone"
                                            name="cellPhone"
                                            className="questionnaire-input-field-sm"
                                            value={formData.cellPhone}
                                            onChange={handleChange}
                                            required />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="spousePhone" className="questionnaire-label">What is your spouse's phone number?</label>
                                        <input
                                            type="number"
                                            id="spousePhone"
                                            name="spousePhone"
                                            className="questionnaire-input-field-sm"
                                            value={formData.spousePhone}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="email" className="questionnaire-label">What is your email address?<span className="required-label">*</span></label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="questionnaire-input-field-sm"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="spouseEmail" className="questionnaire-label">What is your spouse's email address?</label>
                                        <input
                                            type="email"
                                            id="spouseEmail"
                                            name="spouseEmail"
                                            className="questionnaire-input-field-sm"
                                            value={formData.spouseEmail}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="contactMethod" className="questionnaire-label">What is the best contact method?</label>
                                        <input
                                            type="text"
                                            id="contactMethod"
                                            name="contactMethod"
                                            className="questionnaire-input-field-sm"
                                            value={formData.contactMethod}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="priceRange" className="questionnaire-label">What price range are you considering?</label>
                                        <input
                                            type="text"
                                            id="priceRange"
                                            name="priceRange"
                                            className="questionnaire-input-field-sm"
                                            value={formData.priceRange}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="preapproval" className="questionnaire-label">Have you been pre-approved? If so, through whom? What is the approval up to?</label>
                                        <textarea
                                            id="preapproval"
                                            name="preapproval"
                                            className="questionnaire-input-field-lg"
                                            value={formData.preapproval}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="downPayment" className="questionnaire-label">What down payment amount are you considering? Dollar amount or percentage is fine.</label>
                                        <input
                                            type="text"
                                            id="downPayment"
                                            name="downPayment"
                                            className="questionnaire-input-field-sm"
                                            value={formData.downPayment}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="monthlyPaymentLimit" className="questionnaire-label">What monthly payment do you wish to be under?</label>
                                        <input
                                            type="text"
                                            id="monthlyPaymentLimit"
                                            name="monthlyPaymentLimit"
                                            className="questionnaire-input-field-sm"
                                            value={formData.monthlyPaymentLimit}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="beds" className="questionnaire-label">What is the minimum number of bedrooms you require?</label>
                                        <input
                                            type="text"
                                            id="beds"
                                            name="beds"
                                            className="questionnaire-input-field-sm"
                                            value={formData.beds}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="baths" className="questionnaire-label">What is the minimum number of bathrooms you require?</label>
                                        <input
                                            type="text"
                                            id="baths"
                                            name="baths"
                                            className="questionnaire-input-field-sm"
                                            value={formData.baths}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="garageSpaces" className="questionnaire-label">What is the minimum number of garage spaces you require?</label>
                                        <input
                                            type="text"
                                            id="garageSpaces"
                                            name="garageSpaces"
                                            className="questionnaire-input-field-sm"
                                            value={formData.garageSpaces}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="homeAge" className="questionnaire-label">What age of homes are you considering?</label>
                                        <input
                                            type="text"
                                            id="homeAge"
                                            name="homeAge"
                                            className="questionnaire-input-field-sm"
                                            value={formData.homeAge}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="neighborhood" className="questionnaire-label">What suburbs or areas are you looking in?</label>
                                        <textarea
                                            id="neighborhood"
                                            name="neighborhood"
                                            className="questionnaire-input-field-lg"
                                            value={formData.neighborhood}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="moveInGoal" className="questionnaire-label">When do you wish to be in your next home?</label>
                                        <input
                                            type="text"
                                            id="moveInGoal"
                                            name="moveInGoal"
                                            className="questionnaire-input-field-sm"
                                            value={formData.moveInGoal}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="timeInNextHome" className="questionnaire-label">How long do you intend on living in your next home?</label>
                                        <input
                                            type="text"
                                            id="timeInNextHome"
                                            name="timeInNextHome"
                                            className="questionnaire-input-field-sm"
                                            value={formData.timeInNextHome}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="investment" className="questionnaire-label">On a scale of 1 to 10 (1 being investment importance and 10 being utility importance), where would you rank this purchase? 1 is protecting your resale and 10 is making sure you enjoy your home even at the expense of monetary loss. Please add any comments.</label>
                                        <textarea
                                            id="investment"
                                            name="investment"
                                            className="questionnaire-input-field-lg"
                                            value={formData.investment}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="specialCircumstances" className="questionnaire-label">Are there any special circumstances that I should know about?</label>
                                        <textarea
                                            id="specialCircumstances"
                                            name="specialCircumstances"
                                            className="questionnaire-input-field-lg"
                                            value={formData.specialCircumstances}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="isBuildingAnOption" className="questionnaire-label">Would you consider building with either a custom home builder or a national builder? If yes, which developments have you considered or visited?</label>
                                        <textarea
                                            id="isBuildingAnOption"
                                            name="isBuildingAnOption"
                                            className="questionnaire-input-field-lg"
                                            value={formData.isBuildingAnOption}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="specificRequests" className="questionnaire-label">Please advise me of any specific requests you have about your next home (acreage, style, age, etc). The more information the better.</label>
                                        <textarea
                                            id="specificRequests"
                                            name="specificRequests"
                                            className="questionnaire-input-field-lg"
                                            value={formData.specificRequests}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-button-container">
                                    <button type="submit" className="questionnaire-submit-button">Submit</button>
                                </div>
                            </form>
                        </>
                    )} */}
                </div>
            </div>
            <TestimonialsComponent />
            <Footer />
            {success && (
                <div className="questionnaire-success">
                    <div className="questionnaire-fatimes-container">
                        <FaTimes className="questionnaire-success-modal-close" onClick={handleCloseSuccess} />
                    </div>
                    <p className="questionnaire-success-p">
                        Thank you for submitting the buyer questionnaire!
                    </p>
                    <p className="questionnaire-success-p">
                        A member of our team will be in contact within 1 business day.
                    </p>
                </div>
            )}
        </>
    )
}

export default BuyerQuestionnaire