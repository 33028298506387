import React from 'react';
import { Link } from 'react-router-dom';
import { soldItems } from "../../pages/SoldListings/Data";
import '../HSL/HSL.css';

function HSL() {
    return (
        <div className="hsl-container">
            <h1 className="hsl-h1">LUXURY SALES</h1>
            <div className="hsl-items-container">
                {soldItems.filter(item => item.featured).map((soldItem, index) => (
                    <>
                        <div className="hsl-item">
                            <div className="hsl-bg-img-wrapper">
                                <img src={soldItem.img} className="hsl-img" alt={soldItem.address} />
                                <div className="hsl-overlay">
                                    <div className="hsl-overlay-left">
                                        <p className="hsl-overlay-p">
                                            {soldItem.address}
                                        </p>
                                        <p className="hsl-overlay-p">
                                            {soldItem.beds} Bed | {soldItem.baths} Bath | {soldItem.sf} SF
                                        </p>
                                    </div>
                                    <div className="hsl-overlay-right">
                                        <p className="hsl-overlay-p">
                                            {soldItem.price}
                                        </p>
                                    </div>
                                </div>
                                <div className="hsl-item-more-info">
                                    <Link to={soldItem.link} className="hsl-item-more-info-link">More Information</Link>
                                </div>
                            </div>
                        </div>
                        <div className="hsl-overlay-small">
                            <p className="hsl-overlay-p-600">
                                {soldItem.address}
                            </p>
                            <p className="hsl-overlay-p-600">
                                Listed at {soldItem.price}
                            </p>
                            <p className="hsl-overlay-p-sm">
                                {soldItem.beds} Bed | {soldItem.baths} Bath
                            </p>
                            <p className="hsl-overlay-p-sm">
                                {soldItem.sf} SF
                            </p>
                        </div>
                    </>
                ))}
            </div>
            <div className="hsl-button-container">
                <Link to="/sold-listings" className="hsl-search-button">View All</Link>
            </div>
        </div>
    )
}

export default HSL
