import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import MeetTheTeam from './pages/About/MeetTheTeam';
import AboutNevin from './pages/About/AboutNevin';
import AboutElizabeth from './pages/About/AboutElizabeth';
import AboutCassandra from './pages/About/AboutCassandra';
import AboutSelling from './pages/Selling/AboutSelling';
import SellerQuestionnaire from './pages/Selling/SellerQuestionnaire';
import SellingWithNevin from './pages/Selling/SellingWithNevin';
import AboutBuying from './pages/Buying/AboutBuying';
import BuyerQuestionnaire from './pages/Buying/BuyerQuestionnaire';
import BuyingWithNevin from './pages/Buying/BuyingWithNevin';
import Neighborhoods from './pages/Neighborhoods/Neighborhoods';
import WesternSuburbs from './pages/Neighborhoods/WesternSuburbs';
import TwinCities from './pages/Neighborhoods/TwinCities';
import Plymouth from './pages/Neighborhoods/Plymouth';
import MapleGrove from './pages/Neighborhoods/MapleGrove';
import Minnetonka from './pages/Neighborhoods/Minnetonka';
import EdenPrairie from './pages/Neighborhoods/EdenPrairie';
import Nokomis from './pages/Neighborhoods/Nokomis';
import Testimonials from './pages/Testimonials/Testimonials';
import Awards from './pages/Awards/Awards';
import HomeValuation from './pages/HomeValuation/HomeValuation';
import MortgageCalculator from './pages/MortgageCalculator/MortgageCalculator';
import Contact from './pages/Contact/Contact';
import HomeAbout from './components/HomeAbout/HomeAbout';
import HomeAwards from './components/HomeAwards/HomeAwards';
import HFL from './components/HFL/HFL';
import HSL from './components/HSL/HSL';
import Slideshow from './components/Slideshow/Slideshow';
import HCV from './components/HCV/HCV';
import HT from './components/HT/HT';
import NewsletterSignUp from './components/NewsletterSignUp/NewsletterSignUp';
import WorkWithNevin from './components/WorkWithNevin/WorkWithNevin';
import HeroSection from './components/HeroSection/HeroSection';
import FeaturedListings from './pages/FeaturedListings/FeaturedListings';
import SoldListings from './pages/SoldListings/SoldListings';
import ContactForm from './components/ContactForm/ContactForm';
import NavbarBlack from './components/Navbar/NavbarBlack';
import Footer from './components/Footer/Footer';
import TestimonialsComponent from './components/TestimonialsComponent/TestimonialsComponent';
import CashOffer from './pages/CashOffer/CashOffer';
import SlideshowAlternate from './components/Slideshow/SlideshowAlternate';
import HMTT from './components/HMTT/HMTT';
import HomeAlternate from './pages/Home/HomeAlternate';
import HeroSectionAlternate from './components/HeroSection/HeroSectionAlternate';
import HTAlt from './components/HT/HTAlt';
import WWNAlt from './components/WorkWithNevin/WWNAlt';
import NSUAlt from './components/NewsletterSignUp/NSUAlt';
import BQ from './components/BQ/BQ';
import SQ from './components/SQ/SQ';
import NSUForm from './components/NewsletterSignUp/NSUForm';
import CF from './components/ContactForm/CF';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/navbar-black" element={<NavbarBlack />} />
          <Route path="/" element={<Home />} />
          <Route path="/meet-the-team" element={<MeetTheTeam />} />
          <Route path="/about-nevin" element={<AboutNevin />} />
          <Route path="/about-elizabeth" element={<AboutElizabeth />} />
          <Route path="/about-cassandra" element={<AboutCassandra />} />
          <Route path="/about-selling" element={<AboutSelling />} />
          <Route path="/seller-questionnaire" element={<SellerQuestionnaire />} />
          <Route path="/selling-with-nevin" element={<SellingWithNevin />} />
          <Route path="/about-buying" element={<AboutBuying />} />
          <Route path="/buyer-questionnaire" element={<BuyerQuestionnaire />} />
          <Route path="/buying-with-nevin" element={<BuyingWithNevin />} />
          <Route path="/neighborhoods" element={<Neighborhoods />} />
          <Route path="/western-suburbs" element={<WesternSuburbs />} />
          <Route path="/twin-cities" element={<TwinCities />} />
          <Route path="/plymouth" element={<Plymouth />} />
          <Route path="/maple-grove" element={<MapleGrove />} />
          <Route path="/minnetonka" element={<Minnetonka />} />
          <Route path="/eden-prairie" element={<EdenPrairie />} />
          <Route path="/nokomis" element={<Nokomis />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/home-valuation" element={<HomeValuation />} />
          <Route path="/mortgage-calculator" element={<MortgageCalculator />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/home-about" element={<HomeAbout />} />
          <Route path="/home-awards" element={<HomeAwards />} />
          <Route path="/hfl" element={<HFL />} />
          <Route path="/hsl" element={<HSL />} />
          <Route path="/slideshow" element={<Slideshow />} />
          <Route path="/hcv" element={<HCV />} />
          <Route path="/ht" element={<HT />} />
          <Route path="/newsletter-sign-up" element={<NewsletterSignUp />} />
          <Route path="/work-with-nevin" element={<WorkWithNevin />} />
          <Route path="/hero-section" element={<HeroSection />} />
          <Route path="/featured-listings" element={<FeaturedListings />} />
          <Route path="/sold-listings" element={<SoldListings />} />
          <Route path="/contact-form" element={<ContactForm />} />
          <Route path="/testimonials-component" element={<TestimonialsComponent />} />
          <Route path="/cash-offer" element={<CashOffer />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/slideshow-alternate" element={<SlideshowAlternate />} />
          <Route path="/hmtt" element={<HMTT />} />
          <Route path="/home-alternate" element={<HomeAlternate />} />
          <Route path="/hero-alternate" element={<HeroSectionAlternate />} />
          <Route path="/ht-alt" element={<HTAlt />} />
          <Route path="/wwn-alt" element={<WWNAlt />} />
          <Route path="/nsu-alt" element={<NSUAlt />} />
          <Route path="bq" element={<BQ />} />
          <Route path="sq" element={<SQ />} />
          <Route path="nsu-form" element={<NSUForm />} />
          <Route path="cf" element={<CF />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
