import React from 'react';
import { Link } from 'react-router-dom';
import { HFLItems } from '../../pages/FeaturedListings/Data';
import "../HFL/HFL.css";

function HFL() {
    return (
        <div className="hfl-container">
            <h1 className="hfl-h1">FEATURED LISTINGS</h1>
            <div className="hfl-items-container">
                {HFLItems.filter(item => item.featured).map((HFLItem, index) => (
                    <>
                        <div className="hfl-item">
                            <div className="hfl-bg-img-wrapper">
                                <img src={HFLItem.img} className="hfl-img" alt={HFLItem.address} />
                                <div className="hfl-overlay">
                                    <div className="hfl-overlay-left">
                                        <p className="hfl-overlay-p">
                                            {HFLItem.address}
                                        </p>
                                        <p className="hfl-overlay-p">
                                            {HFLItem.beds} Bed | {HFLItem.baths} Bath | {HFLItem.sf} SF
                                        </p>
                                    </div>
                                    <div className="hfl-overlay-right">
                                        <p className="hfl-overlay-p">
                                            {HFLItem.price}
                                        </p>
                                    </div>
                                </div>
                                <div className="hfl-item-more-info">
                                    <Link to={HFLItem.link} className="hfl-item-more-info-link">More Information</Link>
                                </div>
                            </div>
                        </div>
                        <div className="hfl-overlay-small">
                            <p className="hfl-overlay-p-600">
                                {HFLItem.address}
                            </p>
                            <p className="hfl-overlay-p-600">
                                Listed at {HFLItem.price}
                            </p>
                            <p className="hfl-overlay-p-sm">
                                {HFLItem.beds} Bed | {HFLItem.baths} Bath
                            </p>
                            <p className="hfl-overlay-p-sm">
                                {HFLItem.sf} SF
                            </p>
                        </div>
                    </>
                ))}
            </div>
            <div className="hfl-button-container">
                <Link to="/featured-listings" className="hfl-search-button">See All Listings</Link>
            </div>
        </div>
    )
}

export default HFL
