import React from 'react';
import { Link } from 'react-router-dom';
import '../About/About.css';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';
import '../../pages/About/MeetTheTeam.css';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';

function MeetTheTeam() {
    return (
        <>
            <NavbarBlack />
            <div className="main-page-container">
                <h1 className="headline-h1">MEET THE TEAM</h1>
                <p className="about-subheadline">Insight and expertise you can trust</p>
                <div className="about-page-img-wrapper">
                    <img src="/luxury-img.jpg" className="about-page-img" alt="Luxury Home Interior" />
                </div>
                <h1 className="mtt-get-to-know">Get To Know Us</h1>
                <div className="meet-team-links-div">
                    <div className="meet-team-link-wrapper">
                        <Link to="/about-nevin" className="meet-team-link">
                            <p className="meet-team-name">Meet Nevin</p>
                            <img src="/nevin-headshot-transparent-circle.png" className="meet-team-headshot" alt="Nevin Raghuveer" />
                        </Link>
                    </div>
                    <div className="meet-team-link-wrapper">
                        <Link to="/about-elizabeth" className="meet-team-link">
                            <p className="meet-team-name">Meet Elizabeth</p>
                            <img src="/elizabeth-headshot-transparent-circle.png" className="meet-team-headshot" alt="Elizabeth Mullen" />
                        </Link>
                    </div>
                    <div className="meet-team-link-wrapper">
                        <Link to="/about-cassandra" className="meet-team-link">
                            <p className="meet-team-name">Meet Cassandra</p>
                            <img src="/cassandra-headshot-transparent-circle.png" className="meet-team-headshot" alt="Cassandra Bryan" />
                        </Link>
                    </div>
                </div>
                <div className="mtt-bio-container">
                    <div className="mtt-bio-div">
                        <p className="mtt-bio-p">
                            If you're in the market for a new home or would like to sell your current property, consider reaching out to the experienced agents at Nevin Real Estate.
                        </p>
                        <p className="mtt-bio-p">
                            Whether you're a first-time homebuyer or an experienced investor, our team has proven, successful methods to make your home buying or selling process smooth and stress-free. We will work closely with you to understand your needs and to help you achieve your real estate goals.</p>
                        <p className="mtt-bio-p">
                            Our approach works. Our clients consistently outperform the market, and Nevin's success in real estate continues to earn him several prestigious awards and designations. Combined, our team has over 35 years of real estate experience and more than 1,000 homes sold.
                        </p>
                        <p className="mtt-get-to-know">Awards & Designations</p>
                        <div className="mtt-awards-container">
                            <div className="mtt-awards-img-wrap"><img src="/hof.jpg" alt="mtt-awards" className="mtt-awards-img" /></div>
                            <div className="mtt-awards-img-wrap"><img src="/mpls-st-paul.jpg" alt="Minneapolis-St. Paul Magazine SuperAgent" className="mtt-awards-img" /></div>
                            <div className="mtt-awards-img-wrap"><img src="/zillow-awards.jpg" alt="Zillow Awards" className="mtt-awards-img" /></div>
                            <div className="mtt-awards-img-wrap"><img src="/lifetime.jpg" alt="Lifetime Achievement Award" className="mtt-awards-img" /></div>
                            <div className="mtt-awards-img-wrap"><img src="/real-trends-1.jpg" alt="America's Best Real Estate Agents - Real Trends" className="mtt-awards-img" /></div>
                        </div>
                        <div className="mtt-awards-text-div">
                            <p className="mtt-awards-text">
                                #1 RE/MAX Results Agent
                            </p>
                            <p className="mtt-awards-text">
                                #2 RE/MAX Agent in the Midwest
                            </p>
                            <p className="mtt-awards-text">
                                #49 RE/MAX Agent in the Country
                            </p>
                            <p className="mtt-awards-text">
                                #1 RE/MAX Luxury Agent in Minnesota
                            </p>
                        </div>
                    </div>
                </div>
                <Link to="/contact" className="mtt-button">Contact Us</Link>
            </div>
            <TestimonialsComponent />
            <div className="about-parallax">
                <div className="about-parallax-content">
                    <h1 className="about-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="about-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MeetTheTeam
