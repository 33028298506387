import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaEnvelope } from 'react-icons/fa';
import '../About/About.css';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';

function AboutElizabeth() {
    const [slideIndex, setSlideIndex] = useState(1);
    let slideTimer;

    useEffect(() => {
        showSlides(slideIndex);
        startSlideTimer();
        return () => clearTimeout(slideTimer);
    }, [slideIndex]);

    function plusSlides(n) {
        setSlideIndex(prevIndex => prevIndex + n);
        resetSlideTimer();
    };

    function showSlides(n) {
        const slides = document.getElementsByClassName("testimonial-slide");
        let i;
        if (slides.length === 0) return;

        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "block";
    }

    function startSlideTimer() {
        slideTimer = setTimeout(() => {
            plusSlides(1);
        }, 7000);
    };

    function resetSlideTimer() {
        clearTimeout(slideTimer);
        startSlideTimer();
    };

    const handleCall = () => {
        window.location.href = "tel: +19528482578";
    };

    const handleEmail = () => {
        window.location.href = "mailto:elizabeth@nevinrealestate.com";
    };

    return (
        <>
            <NavbarBlack />
            <div className="main-page-container">
                <h1 className="headline-h1">MEET ELIZABETH</h1>
                <p className="about-subheadline">Compassion and expertise with over 15 years of experience</p>
                <div className="about-page-img-wrapper">
                    <img src="/baseball.jpg" className="about-page-img" alt="Baseball" />
                </div>
                <div className="bio-container">
                    <div className="headshot-wrapper">
                        <img src="/elizabeth.png" className="headshot" alt="Elizabeth Mullen" />
                    </div>
                    <div className="about-contact-info">
                        <p className="about-name">Elizabeth Mullen</p>
                        <p className="about-realtor">REALTOR®</p>
                        <div className="about-contact-info-phone-license">
                            <div className="acipl-left">
                                <p className="about-label">
                                    Primary Phone
                                </p>
                                <p className="about-contact-info-text">
                                    (952) 848-2578
                                </p>
                            </div>
                            <div className="acipl-right">
                                <p className="about-label">
                                    License Number
                                </p>
                                <p className="about-contact-info-text">
                                    40447788
                                </p>
                            </div>
                        </div>
                        <p className="about-label">Email</p>
                        <p className="about-contact-info-text">
                            elizabeth@nevinrealestate.com
                        </p>
                        <p className="about-label">
                            Address
                        </p>
                        <p className="about-address">
                            3535 Plymouth Boulevard, Suite 210
                        </p>
                        <p className="about-address">
                            Plymouth, MN 55447
                        </p>
                        <div className="about-social">
                            <div className="about-social-wrapper">
                                <Link to="http://www.facebook.com/nevinrealestate" className="about-social-link">
                                    <FaFacebook className="about-social-icon" />
                                </Link>
                            </div>
                            <div className="about-social-wrapper">
                                <Link to="http://www.instagram.com/nevinrealestate" className="about-social-link">
                                    <FaInstagram className="about-social-icon" />
                                </Link>
                            </div>
                            <div className="about-social-wrapper">
                                <Link to="https://www.linkedin.com/in/elizabeth-mullen-96a9026b" className="about-social-link">
                                    <FaLinkedin className="about-social-icon" />
                                </Link>
                            </div>
                            <div className="about-social-wrapper" onClick={handleCall}>
                                <FaPhone className="about-social-icon" />
                            </div>
                            <div className="about-social-wrapper" onClick={handleEmail}>
                                <FaEnvelope className="about-social-icon" />
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="get-to-know">Get To Know Elizabeth</h1>
                <div className="bio-div">
                    <p className="bio-p">
                        With over 15 years of experience in real estate, Elizabeth brings a wealth of insight to every client interaction. Elizabeth excels in project management, guiding clients in preparing their homes for listing, showing, and obtaining top dollar. Her comprehensive approach also extends beyond traditional buying and selling to encompass a specialized focus on assisting individuals and families throughout the downsizing process.
                    </p>
                    <p className="bio-p">
                        As a seasoned professional, Elizabeth understands that downsizing can be a daunting and emotionally charged endeavor for many. With a keen understanding of the unique challenges involved, she offers tailored solutions that address every aspect of the journey. From strategic planning and resource management to compassionate guidance and support, Elizabeth empowers clients to navigate the downsizing process with confidence and peace of mind. By combining her project management skills with a genuine commitment to client well-being, Elizabeth ensures that each client receives personalized attention and unwavering support every step of the way.
                    </p>
                </div>
                {/* <div className="about-testimonial-div">
                    <div className="at-left-right-container">
                        <div className="at-left">
                            <span className="at-left-span">&ldquo;</span>
                        </div>
                        <div className="at-right">
                            <p className="what-my-clients">
                                What My Clients Say
                            </p>
                            <div className="at-slideshow">
                                <div className="testimonial-slide">
                                    <p className="at-text-p">
                                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae corporis impedit autem. Ullam necessitatibus eius sint nobis suscipit. Eos magni eum temporibus voluptas asperiores quos magnam dolores, non recusandae placeat."
                                    </p>
                                    <p className="at-name">&mdash; Seller</p>
                                </div>
                                <div className="testimonial-slide">
                                    <p className="at-text-p">
                                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae corporis impedit autem. Ullam necessitatibus eius sint nobis suscipit. Eos magni eum temporibus voluptas asperiores quos magnam dolores, non recusandae placeat."
                                    </p>
                                    <p className="at-name">&mdash; Buyer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="at-bottom-div">
                        <Link to="/testimonials" className="at-button">View All</Link>
                    </div>
                </div> */}
            </div>
            <TestimonialsComponent />
            <div className="about-parallax">
                <div className="about-parallax-content">
                    <h1 className="about-parallax-heading">Work With Elizabeth</h1>
                    <Link to="/" className="about-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutElizabeth
