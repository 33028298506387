import React from 'react';
import { Link } from 'react-router-dom';
import { soldItems } from './Data';
import '../SoldListings/SoldListings.css';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';

function SoldListings() {
    return (
        <>
            <NavbarBlack />
            <div className="sold-container">
                <h1 className="sold-h1">LUXURY SALES</h1>
                <div className="sold-page-img-wrapper">
                    <img src="/5115-upland.jpg" className="sold-page-img" alt="Luxury Home" />
                </div>
                <p className="sold-browse">Browse Properties</p>
                {soldItems.map((soldItem, index) => (
                    <div className="sold-item">
                        <div className="sold-bg-img-wrapper">
                            <img src={soldItem.img} className="sold-img" alt={soldItem.address} />
                            <div className="sold-overlay">
                                <p className="sold-overlay-p">
                                    {soldItem.status}
                                </p>
                                <p className="sold-overlay-p">
                                    MLS # {soldItem.mls}
                                </p>
                            </div>
                            {/* <div className="sold-item-more-info">
                                <Link to={soldItem.link} className="sold-item-more-info-link">More Information</Link>
                            </div> */}
                        </div>
                        <div className="sold-about-listing">
                            <p className="sold-about-listing-p-top">
                                {soldItem.price}
                            </p>
                            <p className="sold-about-listing-p">
                                {soldItem.address}, {soldItem.city}
                            </p>
                            <p className="sold-about-listing-p">
                                {soldItem.beds} Bed | {soldItem.baths} Bath | {soldItem.sf} SF
                            </p>
                        </div>
                    </div>
                ))}
                <div className="sold-button-container">
                    <Link to="https://nevinraghuveer.results.net/real-estate" className="sold-search-button">Search All Homes</Link>
                </div>
            </div>
            <div className="sold-parallax">
                <div className="sold-parallax-content">
                    <h1 className="sold-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="sold-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SoldListings
