import React, { useState, useEffect } from 'react';
import "../PrivacyPolicy/PrivacyPolicy.css";
import { Link } from 'react-router-dom';
import { FaPhone, FaChevronCircleLeft, FaChevronCircleRight, FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaPercent, FaCalendar, FaDollarSign, FaMoneyBillTrendUp, FaChartLine, FaHandshake, FaMoneyBill } from "react-icons/fa";
import Footer from '../../components/Footer/Footer';
import NavbarBlack from '../../components/Navbar/NavbarBlack';

function PrivacyPolicy() {
    const handleCall = () => {
        window.location.href = "tel: +19528482475";
    };

    const handleEmail = () => {
        window.location.href = "mailto:nreteam@nevinrealestate.com";
    };

    return (
        <>
            <NavbarBlack />
            <div className="pp-page-container">
                <h1 className="pp-headline-h1">
                    Privacy Policy
                </h1>
                <h2 className="pp-headline-h2">
                    True North Real Estate d/b/a Nevin Real Estate Team with Remax Results
                </h2>
                <h2 className="pp-headline-h2">
                    Effective Date: July 22, 2024
                </h2>
                <p className="pp-paragraph">
                    True North Real Estate, doing business as Nevin Real Estate Team with Remax Results, is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website.
                </p>
                <p className="pp-paragraph-bold">
                    1. Information We Collect
                </p>
                <p className="pp-paragraph">
                    We collect information to understand your needs and provide you with better service. The information we collect includes:
                </p>
                <ul className="pp-list">
                    <li className="pp-list-li">Your IP address</li>
                    <li className="pp-list-li">The date and time of your visit</li>
                    <li className="pp-list-li">Any code provided as part of the URL</li>
                </ul>
                <p className="pp-paragraph">
                    This information is collected for tracking purposes to help us understand how our website is being used.
                </p>
                <p className="pp-paragraph-bold">
                    2. Use of Collected Information
                </p>
                <p className="pp-paragraph">
                    The information we collect is used to:
                </p>
                <ul className="pp-list">
                    <li className="pp-list-li">Monitor and analyze website usage to improve our services</li>
                    <li className="pp-list-li">Ensure the security and proper functioning of our website</li>
                </ul>
                <p className="pp-paragraph-bold">
                    3. Data Sharing and Selling
                </p>
                <p className="pp-paragraph">
                    We do not share, sell, or distribute your personal information to third parties for any purpose, including for marketing or promotional purposes. The data collected (your IP address, visit date and time, and URL code) is solely for our internal use to enhance your experience on our website.
                </p>
                <p className="pp-paragraph-bold">
                    4. Data Security
                </p>
                <p className="pp-paragraph">
                    We are committed to ensuring that your information is secure. We have implemented suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
                </p>
                <p className="pp-paragraph-bold">
                    5. Changes to this Privacy Policy
                </p>
                <p className="pp-paragraph">
                    True North Real Estate d/b/a Nevin Real Estate Team with Remax Results may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </p>
                <p className="pp-paragraph-bold">
                    6. Contact Us
                </p>
                <p className="pp-paragraph">
                    If you have any questions about this Privacy Policy, please contact us at:
                </p>
                <p className="pp-paragraph-bold ppci">
                    True North Real Estate d/b/a Nevin Real Estate Team with Remax Results
                </p>
                <p className="pp-paragraph-bold ppci">
                    3535 Plymouth Blvd., Ste. 210
                </p>
                <p className="pp-paragraph-bold ppci">
                    Plymouth, MN 55447
                </p>
                <p className="pp-paragraph-bold ppci">
                    Web Administrator: Cassandra Bryan
                </p>
                <p className="pp-paragraph-bold ppci">
                    Email: Cassandra@NevinRealEstate.com
                </p>
                <p className="pp-paragraph-bold ppci">
                    Phone: (952) 848-2538
                </p>
                <p className="pp-paragraph pplp">
                    By using our website, you consent to the collection and use of information as described in this Privacy Policy.
                </p>
                <Link to="/" className="pp-home-link">
                    Return to Home Page
                </Link>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy
