import React, { useState, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import "../Buying/AboutBuying.css";
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';

function AboutBuying() {
    const [slideIndex, setSlideIndex] = useState(1);
    let slideTimer;

    useEffect(() => {
        showSlides(slideIndex);
        startSlideTimer();
        return () => clearTimeout(slideTimer);
    }, [slideIndex]);

    function plusSlides(n) {
        setSlideIndex(prevIndex => prevIndex + n);
        resetSlideTimer();
    };

    function showSlides(n) {
        const slides = document.getElementsByClassName("ab-testimonial-slide");
        let i;
        if (slides.length === 0) return;

        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "block";
    }

    function startSlideTimer() {
        slideTimer = setTimeout(() => {
            plusSlides(1);
        }, 7000);
    };

    function resetSlideTimer() {
        clearTimeout(slideTimer);
        startSlideTimer();
    };

    const [abAVisible, setAbAVisible] = useState(Array(10).fill(false));

    const questionsAndAnswers = [
        {
            number: 1,
            question: "What can I expect when buying a home?",
            answer: "When embarking on the journey of purchasing a home, the first step is to consult with the NRE team to define your needs and preferences. Together, we'll discuss your desired location, budget, and must-have features to tailor a personalized search plan. Utilizing our expertise and market insights, we'll set up a comprehensive search on the MLS (Multiple Listing Service), ensuring you have access to all available properties that meet your criteria. As we explore potential homes, the NRE team will guide you through property viewings, providing valuable insights and answering any questions you may have along the way. Once you've found the perfect home, we'll assist you in crafting a competitive offer and negotiating favorable terms with the seller. Throughout the inspection and appraisal process, we'll coordinate necessary appointments and ensure everything proceeds smoothly. As the closing date approaches, we'll work closely with all parties involved to finalize paperwork and address any last-minute details, leading to a successful and stress-free closing. With the NRE team by your side, your homebuying journey is not just a transaction; it's a seamless and rewarding experience from start to finish."
        },
        {
            number: 2,
            question: "How long does the homebuying process typically take from start to finish?",
            answer: "The homebuying process typically takes several weeks to a few months, depending on various factors such as market conditions, mortgage approval timeline, and individual preferences. After consulting with the NRE team to define your needs and preferences, the process begins with searching for suitable properties and ends with the closing, where ownership of the property is transferred to you. Throughout this journey, the NRE team will guide you through each step, ensuring a smooth and efficient process from start to finish."
        },
        {
            number: 3,
            question: "What additional costs should I anticipate beyond the purchase price of the home?",
            answer: "In addition to the purchase price of the home, it's essential to anticipate and budget for various additional costs associated with buying a home. These may include closing costs (such as lender fees, title insurance, and attorney fees), property taxes, homeowners insurance, and potential maintenance or renovation expenses. The NRE team will provide you with a comprehensive breakdown of these costs upfront, helping you to plan and budget accordingly to avoid any surprises down the line."
        },
        {
            number: 4,
            question: "What are some common pitfalls or challenges that first-time homebuyers encounter, and how can I avoid them?",
            answer: "As a first-time homebuyer, it's common to encounter pitfalls or challenges throughout the homebuying process. These may include navigating complex paperwork, understanding the intricacies of mortgage financing, or facing stiff competition in a competitive market. However, with the expertise and guidance of the NRE team, you can navigate these challenges with confidence. Our experienced agents will provide you with valuable insights, expert advice, and proactive support every step of the way, ensuring a smooth and successful homebuying experience."
        },
        {
            number: 5,
            question: "How can I ensure that I'm making a competitive offer on a property without overpaying?",
            answer: "Negotiating the terms of a real estate transaction is a crucial aspect of the homebuying process, and the NRE team excels in this area. With our strong negotiating skills and market knowledge, we can help you craft a competitive offer that maximizes your chances of success while also ensuring that your interests are protected. Whether it's negotiating the purchase price, contingencies, or repair credits, you can trust the NRE team to advocate on your behalf and secure the best possible outcome for your investment."
        },
        {
            number: 6,
            question: "What are the potential benefits of working with a real estate agent, and how do I choose the right one for me?",
            answer: "When it comes to choosing the right real estate agent, trust and expertise are paramount, and the NRE team delivers on both fronts. With a proven track record of success and a reputation for exceptional service, we pride ourselves on building lasting relationships with our clients based on trust, transparency, and integrity. By choosing the NRE team, you'll have access to a dedicated team of professionals who are committed to helping you achieve your real estate goals with confidence and peace of mind."
        },
        {
            number: 7,
            question: "What are the key differences between buying a new construction home and a resale property?",
            answer: "Whether you're considering buying a new construction home or a resale property, the NRE team can provide you with expert guidance and advice every step of the way. We'll help you weigh the pros and cons of each option, considering factors such as cost, customization options, quality of construction, and resale potential. With our in-depth knowledge of the local market and extensive experience in both new construction and resale transactions, you can trust the NRE team to help you make an informed decision that aligns with your needs and preferences."
        },
        {
            number: 8,
            question: "What is the importance of a home inspection, and what should I expect during the inspection process?",
            answer: "A home inspection is a critical step in the homebuying process, and the NRE team understands its importance. We'll help you schedule a comprehensive inspection of the property to identify any potential issues or defects that may need to be addressed before closing. With our guidance, you can navigate the inspection process with confidence, ensuring that you have a clear understanding of the condition of the property and any necessary repairs or maintenance that may be required."
        },
        {
            number: 9,
            question: "What steps do I need to take to prepare for the closing process, and what documents will I need to provide?",
            answer: "As the closing date approaches, the NRE team will work closely with all parties involved to ensure a smooth and successful closing process. We'll help you gather all necessary documentation, coordinate with your lender and title company, and address any last-minute details or concerns. Our goal is to make the closing process as stress-free as possible, allowing you to focus on the excitement of moving into your new home."
        },
        {
            number: 10,
            question: "What resources are available to me for ongoing support and guidance after I've purchased a home?",
            answer: "Even after you've purchased your home, the NRE team will continue to provide you with ongoing support and guidance. Whether you have questions about homeownership, need recommendations for local service providers, or are considering future real estate investments, we're here to help. Our commitment to client satisfaction extends beyond the closing table, and we're dedicated to being your trusted real estate advisors for life."
        },
    ];

    const handleClick = (index) => {
        const newVisible = [...abAVisible];
        newVisible[index] = !newVisible[index];
        setAbAVisible(newVisible);
    };

    return (
        <>
            <NavbarBlack />
            <div className="ab-page-container">
                <h1 className="ab-page-headline-h1">HOME BUYER'S GUIDE</h1>
                <p className="ab-subheadline">A useful resource for potential home sellers</p>
                <div className="ab-img-wrapper">
                    <img src="/luxury-img.jpg" className="ab-img" alt="Luxury Home Interior" />
                </div>
                <p className="ab-faq">Frequently Asked Questions</p>
                <p className="small-screen-intro">
                    We hope this guide containing the questions most frequently asked by our clients will be a benefit to you as you begin your home buying journey. If you would like a more in-depth consultation, our team of experienced professionals are here to assist you.
                </p>
                <div className="ab-main-text-div">
                    {questionsAndAnswers.map((item, index) => (
                        <div key={index}>
                            <p className="ab-q">{index + 1}. {item.question}</p>
                            <p className="ab-a">{item.answer}</p>
                        </div>
                    ))}
                    <div className="ab-button-container">
                        <Link to="/buyer-questionnaire" className="ab-button">Buy With Nevin</Link>
                    </div>
                </div>
            </div>
            <TestimonialsComponent />
            <div className="ab-parallax">
                <div className="ab-parallax-content">
                    <h1 className="ab-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="ab-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutBuying