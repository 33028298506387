import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../Awards/Awards.css";
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';

function Awards() {
    const [slideIndex, setSlideIndex] = useState(1);
    let slideTimer;

    useEffect(() => {
        showSlides(slideIndex);
        startSlideTimer();
        return () => clearTimeout(slideTimer);
    }, [slideIndex]);

    function plusSlides(n) {
        setSlideIndex(prevIndex => prevIndex + n);
        resetSlideTimer();
    };

    function showSlides(n) {
        const slides = document.getElementsByClassName("awards-testimonial-slide");
        let i;
        if (slides.length === 0) return;

        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "block";
    }

    function startSlideTimer() {
        slideTimer = setTimeout(() => {
            plusSlides(1);
        }, 7000);
    };

    function resetSlideTimer() {
        clearTimeout(slideTimer);
        startSlideTimer();
    };

    return (
        <>
            <NavbarBlack />
            <div className="awards-page-container">
                <h1 className="awards-page-headline-h1">AWARDS</h1>
                <div className="awards-img-wrapper">
                    <img src="/luxury-img.jpg" className="awards-img" alt="Luxury Home Interior" />
                </div>
                <p className="aar">Awards and Recognition</p>
                <p className="aar">Received by Nevin Real Estate</p>
                <div className="awards-container">
                    <div className="awards-img-wrap"><img src="/hof.jpg" alt="Awards" className="awards-img" /></div>
                    <div className="awards-img-wrap"><img src="/mpls-st-paul.jpg" alt="Minneapolis-St. Paul Magazine SuperAgent" className="awards-img" /></div>
                    <div className="awards-img-wrap"><img src="/zillow-awards.jpg" alt="Zillow Awards" className="awards-img" /></div>
                    <div className="awards-img-wrap"><img src="/lifetime.jpg" alt="Lifetime Achievement Award" className="awards-img" /></div>
                    <div className="awards-img-wrap"><img src="/real-trends-1.jpg" alt="America's Best Real Estate Agents - Real Trends" className="awards-img" /></div>
                </div>
                <div className="awards-text-div">
                    <p className="awards-text">
                        #1 RE/MAX Results Agent
                    </p>
                    <p className="awards-text">
                        #2 RE/MAX Agent in the Midwest
                    </p>
                    <p className="awards-text">
                        #49 RE/MAX Agent in the Country
                    </p>
                    <p className="awards-text">
                        #1 RE/MAX Luxury Agent in Minnesota
                    </p>
                </div>
                <div className="awards-button-container">
                    <Link to="/contact" className="awards-button">Contact Us</Link>
                </div>
            </div>
            <TestimonialsComponent />
            <div className="awards-parallax">
                <div className="awards-parallax-content">
                    <h1 className="awards-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="awards-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Awards
