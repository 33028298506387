import React from 'react';
import { FaCircle } from 'react-icons/fa';
import '../HCV/HCV.css';

function HCV() {
    return (
        <div className="hcv-container">
            <h1 className="hcv-h1">CORE VALUES</h1>
            <div className="hcv-list">
                <p className="hcv-p">
                    <FaCircle className="hcv-fa" />LEADERSHIP
                </p>
                <p className="hcv-p">
                    <FaCircle className="hcv-fa" />INTEGRITY
                </p>
                <p className="hcv-p">
                    <FaCircle className="hcv-fa" />COMMITMENT
                </p>
                <p className="hcv-p">
                    <FaCircle className="hcv-fa" />PASSION
                </p>
                <p className="hcv-p">
                    <FaCircle className="hcv-fa" />INTELLECT
                </p>
            </div>
        </div>
    )
}

export default HCV
