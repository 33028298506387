import React, { useState, useEffect } from 'react';
import '../Slideshow/SlideshowAlternate.css';

const SlideshowAlternate = () => {
    const [current, setCurrent] = useState(0);

    const Image1 = "/gs/Image1md.jpg";
    const Image2 = "/gs/kitchen4.jpg";
    const Image3 = "/gs/interior1.jpg";
    const Image4 = "/gs/homeexterior.jpg";
    const Image5 = "/gs/Image3md.jpg";
    const Image6 = "/gs/seller-guide-img.jpg";
    const Image7 = "/gs/buyer-faq-img.jpg";
    const Image8 = "/gs/dining.jpg"


    const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8];

    function preloadImages(images) {
        images.forEach(image => {
            const img = new Image();
            img.src = image;
        });
    }

    useEffect(() => {
        preloadImages(images);

        const interval = setInterval(() => {
            setCurrent(current => current === images.length - 1 ? 0 : current + 1);
        }, 7000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="slideshow-alt-container">
            {images.map((img, index) => (
                <div
                    key={index}
                    className={`${index === current ? 'alt-slide' : 'alt-slide'} ${index === current ? (index % 2 === 0 ? 'zoomIn' : 'zoomOut') : ''}`}>
                    {index === current && (
                        <img src={img} alt={`Slide ${index}`} className="w-full h-full object-cover" />
                    )}
                </div>
            ))
            }
        </div>
    )
}

export default SlideshowAlternate;
