import React from 'react';
import { Link } from 'react-router-dom';
import '../WorkWithNevin/WWNAlt.css';

function WWNAlt() {
    return (
        <div className="hw-parallax">
            <div className="hw-parallax-content">
                <h1 className="hw-parallax-heading">Work With Nevin Real Estate</h1>
                <Link to="/" className="hw-parallax-button">Let's Connect</Link>
            </div>
        </div>
    )
}

export default WWNAlt
