import React from 'react';
import "../ContactForm/CF.css";

function CF() {
    return (
        <div>
            <div className="contact-form-div">
                <iframe src="https://interfaces.zapier.com/embed/page/clxyy73zw000ctjsihbncy68u?" className="contact-form"></iframe>
            </div>
        </div>
    )
}

export default CF
