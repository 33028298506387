import React from 'react';
import '../HomeAwards/HomeAwards.css';

function HomeAwards() {
    return (
        <div className="ha-container">
            <div className="ha-h1-div">
                <h1 className="ha-h1">Awards</h1>
            </div>
            <div className="ha-list">
                <img src="/hof.jpg" alt="Awards" className="ha-img" />
                <img src="/mpls-st-paul.jpg" alt="Minneapolis-St. Paul Magazine SuperAgent" className="ha-img" />
                <img src="/zillow-awards.jpg" alt="Zillow Awards" className="ha-img" />
                <img src="/lifetime.jpg" alt="Lifetime Achievement Award" className="ha-img" />
                <img src="/real-trends-1.jpg" alt="America's Best Real Estate Agents - Real Trends" className="ha-img" />
            </div>
        </div>
    )
}

export default HomeAwards
