export const HFLItems = [
    // {
    //     address: '2438 N Blossom Circle',
    //     cityStateZip: 'Orono, MN 55356',
    //     city: 'Mendota Heights',
    //     price: '$635,000',
    //     mls: "6530429",
    //     status: 'Active',
    //     featured: true,
    //     beds: '3',
    //     baths: '3',
    //     sf: '2,236',
    //     type: 'Townhome',
    //     link: 'https://nevinraghuveer.results.net/real-estate/1016-james-court-mendota-heights-mn-55118/538750315/',
    //     img: '/blossom.jpg'
    // },
    {
        address: '1016 James Court',
        cityStateZip: 'Mendota Heights, MN 55118',
        city: 'Mendota Heights',
        price: '$635,000',
        mls: "6530429",
        status: 'Active',
        featured: true,
        beds: '4',
        baths: '3',
        sf: '3,268',
        type: 'Single Family Home',
        link: 'https://nevinraghuveer.results.net/real-estate/1016-james-court-mendota-heights-mn-55118/538750315/',
        img: '/james.jpg'
    },
    {
        address: '13535 Superior Drive',
        cityStateZip: 'Rogers, MN 55374',
        city: 'Rogers',
        price: '$524,900',
        mls: "6545567",
        status: 'Contingent',
        featured: true,
        beds: '4',
        baths: '3',
        sf: '2,720',
        type: 'Single Family Home',
        link: 'https://nevinraghuveer.results.net/real-estate/13535-superior-drive-rogers-mn-55374/539876898/',
        img: '/superior.jpg'
    },
    {
        address: '7690 Urbandale Lane N',
        cityStateZip: 'Maple Grove, MN 55311',
        city: 'Maple Grove',
        price: '$785,000',
        mls: "6526153",
        status: 'Just Sold',
        featured: true,
        beds: '3',
        baths: '3',
        sf: '3,000',
        type: 'Single Family Home',
        link: 'https://nevinraghuveer.results.net/real-estate/7690-urbandale-lane-n-maple-grove-mn-55311/530892893/',
        img: '/urbandale.jpg'
    },
    {
        address: '14715 Dunbar Court',
        cityStateZip: 'Apple Valley, MN 55124',
        city: 'Apple Valley',
        status: 'Just Sold',
        price: '$419,900',
        mls: "6518543",
        beds: '4',
        baths: '3',
        sf: '2,104',
        type: 'Single Family Home',
        link: 'https://nevinraghuveer.results.net/real-estate/14715-dunbar-court-apple-valley-mn-55124/531836610/',
        img: '/dunbar.jpg'
    }
]