export const soldItems = [
    {
        address: '5115 Upland Lane N',
        cityStateZip: 'Plymouth, MN 55446',
        city: 'Plymouth',
        price: '$1,070,000',
        status: "Sold",
        featured: true,
        mls: "6368855",
        beds: '5',
        baths: '5',
        sf: '5,532',
        type: 'Single Family Home',
        img: '/5115-upland.jpg'
    },
    {
        address: '734 Aster Road',
        cityStateZip: 'Medina, MN 55340',
        city: 'Medina',
        price: '$1,065,000',
        status: "Sold",
        featured: true,
        mls: "6172695",
        beds: '5',
        baths: '4',
        sf: '4,946',
        type: 'Single Family Home',
        img: '/734-aster.jpg'
    },
    {
        address: '15600 53rd Avenue N',
        cityStateZip: 'Plymouth, MN 55446',
        city: 'Plymouth',
        price: '$1,056,000',
        status: "Sold",
        featured: true,
        mls: "6155298",
        beds: '5',
        baths: '5',
        sf: '4,846',
        type: 'Single Family Home',
        img: '/15600-53rd.jpg'
    },
    {
        address: '1100 Washburn Avenue S',
        cityStateZip: 'Minneapolis, MN 55405',
        city: 'Bryn Mawr',
        price: '$950,000',
        status: "Sold",
        mls: "6160293",
        beds: '4',
        baths: '4',
        sf: '3,797',
        type: 'Single Family Home',
        img: '/1100-washburn.jpg'
    },
    {
        address: '17734 58th Circle N',
        cityStateZip: 'Plymouth, MN 55446',
        city: 'Plymouth',
        price: '$877,500',
        status: "Sold",
        mls: "6341467",
        beds: '5',
        baths: '5',
        sf: '4,058',
        type: 'Single Family Home',
        img: '/17734-58th.jpg'
    },
    {
        address: '16441 74th Avenue N',
        cityStateZip: 'Maple Grove, MN 55311',
        city: 'Maple Grove',
        price: '$875,000',
        status: "Sold",
        mls: "5730606",
        beds: '5',
        baths: '5',
        sf: '5,172',
        type: 'Single Family Home',
        img: '/16441-74th.jpg'
    },
    {
        address: '6251 Queensland Lane N',
        cityStateZip: 'Maple Grove, MN 55311',
        city: 'Maple Grove',
        price: '$860,000',
        status: "Sold",
        mls: "6162996",
        beds: '5',
        baths: '5',
        sf: '4,175',
        type: 'Single Family Home',
        img: '/6251-queensland.jpg'
    },
    {
        address: '3169 Cypress Circle S',
        cityStateZip: 'Medina, MN 55340',
        city: 'Medina',
        price: '$855,000',
        status: "Sold",
        mls: "6229299",
        beds: '6',
        baths: '5',
        sf: '4,656',
        type: 'Single Family Home',
        img: '/3169-cypress.jpg'
    },
    {
        address: '697 Aster Road',
        cityStateZip: 'Medina, MN 55340',
        city: 'Medina',
        price: '$855,000',
        status: "Sold",
        mls: "5727923",
        beds: '6',
        baths: '5',
        sf: '6,152',
        type: 'Single Family Home',
        img: '/697-aster.jpg'
    },
    {
        address: '150 Prairie Creek Road',
        cityStateZip: 'Medina, MN 55340',
        city: 'Medina',
        price: '$828,500',
        status: "Sold",
        mls: "5666203",
        beds: '6',
        baths: '5',
        sf: '5,417',
        type: 'Single Family Home',
        img: '/150-prairie-creek.jpg'
    },
    {
        address: '16620 54th Avenue N',
        cityStateZip: 'Plymouth, MN 55446',
        city: 'Plymouth',
        price: '$825,000',
        status: "Sold",
        mls: "5752904",
        beds: '5',
        baths: '5',
        sf: '4,282',
        type: 'Single Family Home',
        img: '/16620-54th.jpg'
    },
    {
        address: '714 Aster Road',
        cityStateZip: 'Medina, MN 55340',
        city: 'Medina',
        price: '$800,000',
        status: "Sold",
        mls: "5709092",
        beds: '5',
        baths: '5',
        sf: '4,359',
        type: 'Single Family Home',
        img: '/714-aster.jpg'
    },
    {
        address: '14745 50th Place N',
        cityStateZip: 'Plymouth, MN 55446',
        city: 'Plymouth',
        price: '$775,000',
        status: "Sold",
        mls: "6366354",
        beds: '5',
        baths: '5',
        sf: '4,093',
        type: 'Single Family Home',
        img: '/14745-50th.jpg'
    },
    {
        address: '1092 Jubert Trail',
        cityStateZip: 'Medina, MN 55340',
        city: 'Medina',
        price: '$775,000',
        status: "Sold",
        mls: "6318119",
        beds: '5',
        baths: '4',
        sf: '4,182',
        type: 'Single Family Home',
        img: '/1092-jubert.jpg'
    },
    {
        address: '18055 66th Place N',
        cityStateZip: 'Maple Grove, MN 55311',
        city: 'Maple Grove',
        price: '$767,000',
        status: "Sold",
        mls: "6423112",
        beds: '5',
        baths: '3',
        sf: '3,718',
        type: 'Single Family Home',
        img: '/18055-66th.jpg'
    },
    {
        address: '4125 Shorewood Trail',
        cityStateZip: 'Medina, MN 55340',
        city: 'Medina',
        price: '$765,000',
        status: "Sold",
        mls: "5616301",
        beds: '5',
        baths: '5',
        sf: '5,355',
        type: 'Single Family Home',
        img: '/4125-shorewood.jpg'
    }
]