import React from 'react';
import "../BQ/BQ.css";

function BQ() {
    return (
        <div>
            <div className="bq-form-div">
                <iframe src="https://interfaces.zapier.com/embed/page/clxyw5kd800353o7knicdti7q" className="bq-form"></iframe>
            </div>
        </div>
    )
}

export default BQ
