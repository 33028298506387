import React from 'react'

function Minnetonka() {
    return (
        <div>

        </div>
    )
}

export default Minnetonka
