import React from 'react';
import { Link } from 'react-router-dom';
import { HFLItems } from './Data';
import '../FeaturedListings/FeaturedListings.css';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';

function FeaturedListings() {
    return (
        <>
            <NavbarBlack />
            <div className="fl-container">
                <h1 className="fl-h1">FEATURED LISTINGS</h1>
                <div className="fl-page-img-wrapper">
                    <img src="/home-night.jpg" className="fl-page-img" alt="Home Exterior" />
                </div>
                <p className="fl-browse">Browse Properties</p>
                {HFLItems.map((HFLItem, index) => (
                    <div className="fl-item">
                        <div className="fl-bg-img-wrapper">
                            <img src={HFLItem.img} className="fl-img" alt={HFLItem.address} />
                            <div className="fl-overlay">
                                <p className="fl-overlay-p">
                                    {HFLItem.status}
                                </p>
                                <p className="fl-overlay-p">
                                    MLS # {HFLItem.mls}
                                </p>
                            </div>
                            <div className="fl-item-more-info">
                                <Link to={HFLItem.link} className="fl-item-more-info-link">More Information</Link>
                            </div>
                        </div>
                        <div className="fl-about-listing">
                            <p className="fl-about-listing-p-top">
                                {HFLItem.price}
                            </p>
                            <p className="fl-about-listing-p">
                                {HFLItem.address}, {HFLItem.city}
                            </p>
                            <p className="fl-about-listing-p">
                                {HFLItem.beds} Bed | {HFLItem.baths} Bath | {HFLItem.sf} SF
                            </p>
                        </div>
                    </div>
                ))}
                <div className="fl-button-container">
                    <Link to="https://nevinraghuveer.results.net/real-estate" className="fl-search-button">Search All Homes</Link>
                </div>
            </div>
            <div className="fl-parallax">
                <div className="fl-parallax-content">
                    <h1 className="fl-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="fl-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default FeaturedListings
