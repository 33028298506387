import React from 'react';
import { Link } from 'react-router-dom';
import Slideshow from '../Slideshow/Slideshow';
import '../HeroSection/HeroSectionAlternate.css';
import SlideshowAlternate from '../Slideshow/SlideshowAlternate';

function HeroSectionAlternate() {
    return (
        <div className="hero-section-main-container">
            <div className="hero-section-background-container">
                <div className="slideshow-wrapper">
                    <SlideshowAlternate className="hero-section-slideshow" />
                </div>
            </div>
            <div className="hero-section-container">
                <div className="hero-section-contents">
                    <p className="hero-section-top-text">Welcome To</p>
                    <p className="hero-section-headline">
                        Nevin Real Estate
                    </p>
                    <p className="hero-section-subheadline">
                        #1 RE/MAX RESULTS REALTOR NEVIN RAGHUVEER
                    </p>
                    <div className="hero-section-button-container">
                        <Link to="https://nevinraghuveer.results.net/real-estate" className="hero-section-button" target="_blank">Search All Homes</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSectionAlternate
