import React from 'react';
import { Link } from 'react-router-dom';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';
import "../CashOffer/CashOffer.css";

function CashOffer() {
    return (
        <>
            <NavbarBlack />
            <div className="main-page-container">
                <h1 className="headline-h1">CASH OFFER</h1>
                <p className="co-subheadline">Take the stress out of selling with our cash offer program</p>
                <div className="co-page-img-wrapper">
                    <img src="/luxury-img.jpg" className="co-page-img" alt="Luxury Home Interior" />
                </div>
                <h1 className="easier-way">An Easier Way to Sell</h1>
                <div className="co-text-div">
                    <p className="co-text-p">
                        Nevin Real Estate is proud to offer a cash offer program designed with sellers' ease in mind.
                    </p>
                    <p className="co-text-p">
                        It's easy! Just click the button below, enter your address, and answer a few short questions. We'll review it, and if your home qualifies for the program, you'll receive your non-binding cash offer.
                    </p>
                    <p className="co-text-p">
                        Have the peace of mind of selling your home without the preparation, showings, negotiation, and hassle!
                    </p>
                </div>
                <Link to="/" className="co-button">Get Started</Link>
            </div>
            <TestimonialsComponent />
            <div className="co-parallax">
                <div className="co-parallax-content">
                    <h1 className="co-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="co-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default CashOffer
