import React from 'react';
import "../SQ/SQ.css";

function SQ() {
    return (
        <div>
            <div className="sq-form-div">
                <iframe src="https://interfaces.zapier.com/embed/page/clxyqfh64000ljf0l1s904s0u?" className="sq-form"></iframe>
            </div>
        </div>
    )
}

export default SQ
