import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';
import "../Selling/SellerQuestionnaire.css";
import { FaTimes } from 'react-icons/fa';
import SQ from '../../components/SQ/SQ';

function SellerQuestionnaire() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        spouseFirstName: '',
        spouseLastName: '',
        homePhone: '',
        cellPhone: '',
        spousePhone: '',
        email: '',
        spouseEmail: '',
        contactMethod: '',
        address: '',
        yearBuilt: '',
        homeType: '',
        bedsBaths: '',
        masterBath: '',
        separateTubShower: '',
        fireplace: '',
        garage: '',
        sqft: '',
        basement: '',
        basementWalkoutEgress: '',
        backyard: '',
        exterior: '',
        amenities: '',
        woodwork: '',
        countertops: '',
        flooringType: '',
        roof: '',
        siding: '',
        hvac: '',
        furnace: '',
        driveway: '',
        windows: '',
        flooringAgeCondition: '',
        whenPurchasedHowMuch: '',
        conditionOfHome: '',
        otherInfo: '',
        primaryMortgage: '',
        secondaryMortgage: '',
        fhaOrConv: ''
    });

    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.firstName || !formData.lastName || !formData.cellPhone || !formData.email) {
            alert('Please fill out all required fields.');
            return;
        }

        try {
            await axios.post('https://82.180.133.122/api/sellers', formData);
            setFormData({
                firstName: '',
                lastName: '',
                spouseFirstName: '',
                spouseLastName: '',
                homePhone: '',
                cellPhone: '',
                spousePhone: '',
                email: '',
                spouseEmail: '',
                contactMethod: '',
                address: '',
                yearBuilt: '',
                homeType: '',
                bedsBaths: '',
                masterBath: '',
                separateTubShower: '',
                fireplace: '',
                garage: '',
                sqft: '',
                basement: '',
                basementWalkoutEgress: '',
                backyard: '',
                exterior: '',
                amenities: '',
                woodwork: '',
                countertops: '',
                flooringType: '',
                roof: '',
                siding: '',
                hvac: '',
                furnace: '',
                driveway: '',
                windows: '',
                flooringAgeCondition: '',
                whenPurchasedHowMuch: '',
                conditionOfHome: '',
                otherInfo: '',
                primaryMortgage: '',
                secondaryMortgage: '',
                fhaOrConv: ''
            });
            setSuccess(true);
        } catch (err) {
            console.error(err);
            alert('Something went wrong');
        }
    };

    const handleCloseSuccess = () => {
        setSuccess(false);
    };

    return (
        <>
            <NavbarBlack />
            <div className="questionnaire-page-container">
                <h1 className="questionnaire-headline-h1">SELLER QUESTIONNAIRE</h1>
                <p className="questionnaire-subheadline">The first step on your home sale journey</p>
                <div className="questionnaire-page-img-wrapper">
                    <img src="/luxury-img.jpg" className="questionnaire-page-img" alt="Luxury Home Interior" />
                </div>
                <div className="main-form-container">
                    <SQ className="sq" />
                    {/* {success ? (
                        <div className="submission-success-message">
                            <p className="ssm-p">Thank you for submitting the questionnaire.</p>
                            <p className="ssm-p">A member of our team will be in contact shortly.</p>
                            <div className="rtn-home-button-container">
                                <Link to="/" className="rtn-home-button">Return Home</Link>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="questionnaire-intro">
                                <p className="qi-p">In order for us to better and more accurately serve you, please fill out our personalized seller questionnaire below.</p>
                                <p className="qi-p">
                                    We will be in contact shortly after receiving your form.
                                </p>
                            </div>
                            <div className="asterisk-means">Fields marked with a <span className="required-label">*</span> are required.</div>
                            <form onSubmit={handleSubmit} className="intake-form">
                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="firstName" className="questionnaire-label">First Name:<span className="required-label">*</span></label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            className="questionnaire-input-field-sm"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            required />
                                    </div>
                                </div>
                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="lastName" className="questionnaire-label">Last Name:<span className="required-label">*</span></label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            className="questionnaire-input-field-sm"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="spouseFirstName" className="questionnaire-label">What is your husband's / wife's first name?</label>
                                        <input
                                            type="text"
                                            id="spouseFirstName"
                                            name="spouseFirstName"
                                            className="questionnaire-input-field-sm"
                                            value={formData.spouseFirstName}
                                            onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="spouseLastName" className="questionnaire-label">What is your husband's / wife's last name?</label>
                                        <input
                                            type="text"
                                            id="spouseLastName"
                                            name="spouseLastName"
                                            className="questionnaire-input-field-sm"
                                            value={formData.spouseLastName}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="homePhone" className="questionnaire-label">What is your HOME phone number?</label>
                                        <input
                                            type="number"
                                            id="homePhone"
                                            name="homePhone"
                                            className="questionnaire-input-field-sm"
                                            value={formData.homePhone}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="cellPhone" className="questionnaire-label">What is your CELL phone number?<span className="required-label">*</span></label>
                                        <input
                                            type="number"
                                            id="cellPhone"
                                            name="cellPhone"
                                            className="questionnaire-input-field-sm"
                                            value={formData.cellPhone}
                                            onChange={handleChange}
                                            required />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="spousePhone" className="questionnaire-label">What is your spouse's phone number?</label>
                                        <input
                                            type="number"
                                            id="spousePhone"
                                            name="spousePhone"
                                            className="questionnaire-input-field-sm"
                                            value={formData.spousePhone}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="email" className="questionnaire-label">What is your email address?<span className="required-label">*</span></label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="questionnaire-input-field-sm"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="spouseEmail" className="questionnaire-label">What is your spouse's email address?</label>
                                        <input
                                            type="email"
                                            id="spouseEmail"
                                            name="spouseEmail"
                                            className="questionnaire-input-field-sm"
                                            value={formData.spouseEmail}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="contactMethod" className="questionnaire-label">What is the best contact method?</label>
                                        <input
                                            type="text"
                                            id="contactMethod"
                                            name="contactMethod"
                                            className="questionnaire-input-field-sm"
                                            value={formData.contactMethod}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="address" className="questionnaire-label">What is the listing property address?</label>
                                        <textarea
                                            id="address"
                                            name="address"
                                            className="questionnaire-input-field-lg"
                                            value={formData.address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="yearBuilt" className="questionnaire-label">When was it built?</label>
                                        <input
                                            type="text"
                                            id="yearBuilt"
                                            name="yearBuilt"
                                            className="questionnaire-input-field-sm"
                                            value={formData.yearBuilt}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="homeType" className="questionnaire-label">Is it a single family home or a townhome?</label>
                                        <textarea
                                            id="homeType"
                                            name="homeType"
                                            className="questionnaire-input-field-lg"
                                            value={formData.homeType}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="bedsBaths" className="questionnaire-label">How many total bedrooms and bathrooms (full, 1/2, etc) on each level?</label>
                                        <input
                                            type="text"
                                            id="bedsBaths"
                                            name="bedsBaths"
                                            className="questionnaire-input-field-sm"
                                            value={formData.bedsBaths}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="masterBath" className="questionnaire-label">Is there a master bath? If so, please briefly describe.</label>
                                        <textarea
                                            id="masterBath"
                                            name="masterBath"
                                            className="questionnaire-input-field-lg"
                                            value={formData.masterBath}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="separateTubShower" className="questionnaire-label">Do any bathrooms have a separate tub and shower?</label>
                                        <textarea
                                            id="separateTubShower"
                                            name="separateTubShower"
                                            className="questionnaire-input-field-lg"
                                            value={formData.separateTubShower}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="fireplace" className="questionnaire-label">Does the home have a fireplace? If so, how many and where are they located? Please include if gas or wood burning.</label>
                                        <textarea
                                            id="fireplace"
                                            name="fireplace"
                                            className="questionnaire-input-field-lg"
                                            value={formData.fireplace}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="garage" className="questionnaire-label">Does the home have a garage? If so, how many stalls? Is it attached or detached? Are there any special features (heated, insulated, etc)?</label>
                                        <textarea
                                            id="garage"
                                            name="garage"
                                            className="questionnaire-input-field-lg"
                                            value={formData.garage}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="sqft" className="questionnaire-label">What is the square footage above ground (not including your basement)? Please include foundation size if you are aware.</label>
                                        <input
                                            type="text"
                                            id="sqft"
                                            name="sqft"
                                            className="questionnaire-input-field-sm"
                                            value={formData.sqft}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="basement" className="questionnaire-label">Is your basement finished? If so, what is the total square footage? Describe the rooms/amenities. Who finished the lower level?</label>
                                        <textarea
                                            id="basement"
                                            name="basement"
                                            className="questionnaire-input-field-lg"
                                            value={formData.basement}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="basementWalkoutEgress" className="questionnaire-label">Is the basement a walkout or a lookout? Does it have egress windows?</label>
                                        <input
                                            type="text"
                                            id="basementWalkoutEgress"
                                            name="basementWalkoutEgress"
                                            className="questionnaire-input-field-sm"
                                            value={formData.basementWalkoutEgress}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="backyard" className="questionnaire-label">Describe your backyard size, views, and any landscaping you have.</label>
                                        <textarea
                                            id="backyard"
                                            name="backyard"
                                            className="questionnaire-input-field-lg"
                                            value={formData.backyard}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="exterior" className="questionnaire-label">What type of exterior do you have (brick, stone, siding, etc)?</label>
                                        <input
                                            type="text"
                                            id="exterior"
                                            name="exterior"
                                            className="questionnaire-input-field-sm"
                                            value={formData.exterior}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="amenities" className="questionnaire-label">List all amenities (big or small). Everything matters so don't worry about too much info!</label>
                                        <textarea
                                            id="amenities"
                                            name="amenities"
                                            className="questionnaire-input-field-lg"
                                            value={formData.amenities}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="woodwork" className="questionnaire-label">What type of woodwork do you have (oak, maple, cherry, etc) and what is the condition?</label>
                                        <input
                                            type="text"
                                            id="woodwork"
                                            name="woodwork"
                                            className="questionnaire-input-field-sm"
                                            value={formData.woodwork}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="countertops" className="questionnaire-label">What type of countertops do you have (solid surface, laminate, etc) and what is the condition?</label>
                                        <input
                                            type="text"
                                            id="countertops"
                                            name="countertops"
                                            className="questionnaire-input-field-sm"
                                            value={formData.countertops}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="flooringType" className="questionnaire-label">What type of flooring do you have (wood, tile, vinyl, etc)?</label>
                                        <input
                                            type="text"
                                            id="flooringType"
                                            name="flooringType"
                                            className="questionnaire-input-field-sm"
                                            value={formData.flooringType}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="roof" className="questionnaire-label">What is the age and condition of your roof?</label>
                                        <input
                                            type="text"
                                            id="roof"
                                            name="roof"
                                            className="questionnaire-input-field-sm"
                                            value={formData.roof}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="siding" className="questionnaire-label">What is the age and condition of your siding?</label>
                                        <input
                                            type="text"
                                            id="siding"
                                            name="siding"
                                            className="questionnaire-input-field-sm"
                                            value={formData.siding}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="hvac" className="questionnaire-label">What is the age and condition of your A/C?</label>
                                        <input
                                            type="text"
                                            id="hvac"
                                            name="hvac"
                                            className="questionnaire-input-field-sm"
                                            value={formData.hvac}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="furnace" className="questionnaire-label">What is the age and condition of your furnace?</label>
                                        <input
                                            type="text"
                                            id="furnace"
                                            name="furnace"
                                            className="questionnaire-input-field-sm"
                                            value={formData.furnace}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="driveway" className="questionnaire-label">What is the age and condition of your driveway?</label>
                                        <input
                                            type="text"
                                            id="driveway"
                                            name="driveway"
                                            className="questionnaire-input-field-sm"
                                            value={formData.driveway}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="windows" className="questionnaire-label">What is the age and condition of your windows?</label>
                                        <input
                                            type="text"
                                            id="windows"
                                            name="windows"
                                            className="questionnaire-input-field-sm"
                                            value={formData.windows}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="flooringAgeCondition" className="questionnaire-label">What is the age and condition of your flooring?</label>
                                        <input
                                            type="text"
                                            id="flooringAgeCondition"
                                            name="flooringAgeCondition"
                                            className="questionnaire-input-field-sm"
                                            value={formData.flooringAgeCondition}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="whenPurchasedHowMuch" className="questionnaire-label">When did you buy your home and what did you pay for it?</label>
                                        <input
                                            type="text"
                                            id="whenPurchasedHowMuch"
                                            name="whenPurchasedHowMuch"
                                            className="questionnaire-input-field-sm"
                                            value={formData.whenPurchasedHowMuch}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="conditionOfHome" className="questionnaire-label">On a scale of 1-10, rate the condition of your home (10 being the best).</label>
                                        <input
                                            type="text"
                                            id="conditionOfHome"
                                            name="conditionOfHome"
                                            className="questionnaire-input-field-sm"
                                            value={formData.conditionOfHome}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label htmlFor="otherInfo" className="questionnaire-label">Feel free to add in any other key points here:</label>
                                        <textarea
                                            id="otherInfo"
                                            name="otherInfo"
                                            className="questionnaire-input-field-lg"
                                            value={formData.otherInfo}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="primaryMortgage" className="questionnaire-label">What is the interest rate and approximate balance owed to your primary mortgage company?</label>
                                        <input
                                            type="text"
                                            id="primaryMortgage"
                                            name="primaryMortgage"
                                            className="questionnaire-input-field-sm"
                                            value={formData.primaryMortgage}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="secondaryMortgage" className="questionnaire-label">What is the interest rate and approximate balance owed to your secondary mortgage company?</label>
                                        <input
                                            type="text"
                                            id="secondaryMortgage"
                                            name="secondaryMortgage"
                                            className="questionnaire-input-field-sm"
                                            value={formData.secondaryMortgage}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-input">
                                    <div className="label-input-wrapper">
                                        <label
                                            htmlFor="fhaOrConv" className="questionnaire-label">Do you have an FHA or conventional mortgage?</label>
                                        <input
                                            type="text"
                                            id="fhaOrConv"
                                            name="fhaOrConv"
                                            className="questionnaire-input-field-sm"
                                            value={formData.fhaOrConv}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="questionnaire-button-container">
                                    <button type="submit" className="questionnaire-submit-button">Submit</button>
                                </div>
                            </form>
                        </>
                    )} */}
                </div>
            </div>
            <TestimonialsComponent />
            <Footer />
            {success && (
                <div className="questionnaire-success">
                    <div className="questionnaire-fatimes-container">
                        <FaTimes className="questionnaire-success-modal-close" onClick={handleCloseSuccess} />
                    </div>
                    <p className="questionnaire-success-p">
                        Thank you for submitting the seller questionnaire!
                    </p>
                    <p className="questionnaire-success-p">
                        A member of our team will be in contact within 1 business day.
                    </p>
                </div>
            )}
        </>
    )
}

export default SellerQuestionnaire