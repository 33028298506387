import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import HeroSectionAlternate from '../../components/HeroSection/HeroSectionAlternate';
import HomeAbout from '../../components/HomeAbout/HomeAbout';
import HFL from '../../components/HFL/HFL';
import HomeAwards from '../../components/HomeAwards/HomeAwards';
import HT from '../../components/HT/HT';
import HCV from '../../components/HCV/HCV';
import HSL from '../../components/HSL/HSL';
import NewsletterSignUp from '../../components/NewsletterSignUp/NewsletterSignUp';
import WorkWithNevin from '../../components/WorkWithNevin/WorkWithNevin';
import '../Home/HomeAlternate.css';
import Footer from '../../components/Footer/Footer';
import HTAlt from '../../components/HT/HTAlt';
import WWNAlt from '../../components/WorkWithNevin/WWNAlt';
import NSUAlt from '../../components/NewsletterSignUp/NSUAlt';

function HomeAlternate() {
    return (
        <div>
            <HeroSectionAlternate />
            <HomeAbout />
            <div className="ha-parallax">
                <HomeAwards />
            </div>
            <HFL />
            <HTAlt />
            <div className="hcv-parallax">
                <HCV />
            </div>
            <HSL />
            <NSUAlt />
            <WWNAlt />
            <Footer />
        </div>
    )
}

export default HomeAlternate
