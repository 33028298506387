import React from 'react'

function TwinCities() {
    return (
        <div>

        </div>
    )
}

export default TwinCities
