import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaEnvelope } from 'react-icons/fa';
import '../About/About.css';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';

function AboutCassandra() {
    const [slideIndex, setSlideIndex] = useState(1);
    let slideTimer;

    useEffect(() => {
        showSlides(slideIndex);
        startSlideTimer();
        return () => clearTimeout(slideTimer);
    }, [slideIndex]);

    function plusSlides(n) {
        setSlideIndex(prevIndex => prevIndex + n);
        resetSlideTimer();
    };

    function showSlides(n) {
        const slides = document.getElementsByClassName("testimonial-slide");
        let i;
        if (slides.length === 0) return;

        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "block";
    }

    function startSlideTimer() {
        slideTimer = setTimeout(() => {
            plusSlides(1);
        }, 7000);
    };

    function resetSlideTimer() {
        clearTimeout(slideTimer);
        startSlideTimer();
    };

    const handleCall = () => {
        window.location.href = "tel: +19528482538";
    };

    const handleEmail = () => {
        window.location.href = "mailto:cassandra@nevinrealestate.com";
    };

    return (
        <>
            <NavbarBlack />
            <div className="main-page-container">
                <h1 className="headline-h1">MEET CASSANDRA</h1>
                <p className="about-subheadline">Dedicated to cutting-edge marketing and seamless transactions</p>
                <div className="about-page-img-wrapper">
                    <img src="/orange-forest.jpg" className="about-page-img" alt="Autumn Forest" />
                </div>
                <div className="bio-container">
                    <div className="headshot-wrapper">
                        <img src="/cassandra-headshot-transparent.png" className="headshot" alt="Cassandra Bryan" />
                    </div>
                    <div className="about-contact-info">
                        <p className="about-name">Cassandra Bryan</p>
                        <p className="about-realtor">REALTOR®</p>
                        <div className="about-contact-info-phone-license">
                            <div className="acipl-left">
                                <p className="about-label">
                                    Primary Phone
                                </p>
                                <p className="about-contact-info-text">
                                    (952) 848-2538
                                </p>
                            </div>
                            <div className="acipl-right">
                                <p className="about-label">
                                    License Number
                                </p>
                                <p className="about-contact-info-text">
                                    40745500
                                </p>
                            </div>
                        </div>
                        <p className="about-label">Email</p>
                        <p className="about-contact-info-text">
                            cassandra@nevinrealestate.com
                        </p>
                        <p className="about-label">
                            Address
                        </p>
                        <p className="about-address">
                            3535 Plymouth Boulevard, Suite 210
                        </p>
                        <p className="about-address">
                            Plymouth, MN 55447
                        </p>
                        <div className="about-social">
                            <div className="about-social-wrapper">
                                <Link to="http://www.facebook.com/nevinrealestate" className="about-social-link">
                                    <FaFacebook className="about-social-icon" />
                                </Link>
                            </div>
                            <div className="about-social-wrapper">
                                <Link to="http://www.instagram.com/nevinrealestate" className="about-social-link">
                                    <FaInstagram className="about-social-icon" />
                                </Link>
                            </div>
                            <div className="about-social-wrapper">
                                <Link to="http://www.linkedin.com" className="about-social-link">
                                    <FaLinkedin className="about-social-icon" />
                                </Link>
                            </div>
                            <div className="about-social-wrapper" onClick={handleCall}>
                                <FaPhone className="about-social-icon" />
                            </div>
                            <div className="about-social-wrapper" onClick={handleEmail}>
                                <FaEnvelope className="about-social-icon" />
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="get-to-know">Get To Know Cassandra</h1>
                <div className="bio-div">
                    <p className="bio-p">
                        Cassandra has been a licensed realtor since 2021. With a keen eye for data, detail, and design, she brings a fresh perspective and dynamic, tech-driven approach to the Nevin Real Estate team. Prior to obtaining her license, she held key roles in real estate administration, focusing on compliance, training, and portfolio management. Her comprehensive understanding of the market's operational and regulatory aspects enables her to deliver quality service tailored to each client's needs.
                    </p>
                    <p className="bio-p">
                        Outside of work, Cassandra spends her time hiking, biking, and kayaking, and coding full stack development projects with her dog, Tank, by her side.
                    </p>
                </div>
                {/* <div className="about-testimonial-div">
                    <div className="at-left-right-container">
                        <div className="at-left">
                            <span className="at-left-span">&ldquo;</span>
                        </div>
                        <div className="at-right">
                            <p className="what-my-clients">
                                What My Clients Say
                            </p>
                            <div className="at-slideshow">
                                <div className="testimonial-slide">
                                    <p className="at-text-p">
                                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae corporis impedit autem. Ullam necessitatibus eius sint nobis suscipit. Eos magni eum temporibus voluptas asperiores quos magnam dolores, non recusandae placeat."
                                    </p>
                                    <p className="at-name">&mdash; Seller</p>
                                </div>
                                <div className="testimonial-slide">
                                    <p className="at-text-p">
                                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae corporis impedit autem. Ullam necessitatibus eius sint nobis suscipit. Eos magni eum temporibus voluptas asperiores quos magnam dolores, non recusandae placeat."
                                    </p>
                                    <p className="at-name">&mdash; Buyer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="at-bottom-div">
                        <Link to="/testimonials" className="at-button">View All</Link>
                    </div>
                </div> */}
            </div>
            <TestimonialsComponent />
            <div className="about-parallax">
                <div className="about-parallax-content">
                    <h1 className="about-parallax-heading">Work With Cassandra</h1>
                    <Link to="/" className="about-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutCassandra
