import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Navbar/Navbar.css';
import { FaChevronDown, FaBars, FaTimes, FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaPhone, FaChevronUp } from 'react-icons/fa';
import { IconContext } from 'react-icons';

function Navbar() {
    const location = useLocation();

    const [backgroundColor, setBackgroundColor] = useState('rgba(37,39,79,1)');
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [dropdown, setDropdown] = useState(false);
    const [propertyChevronClicked, setPropertyChevronClicked] = useState(false);
    const [aboutChevronClicked, setAboutChevronClicked] = useState(false);
    const [sellingChevronClicked, setSellingChevronClicked] = useState(false);
    const [buyingChevronClicked, setBuyingChevronClicked] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    useEffect(() => {
        const checkAndSetBackgroundColor = () => {
            const currentPath = location.pathname;
            if (currentPath === '/') {
                setBackgroundColor('transparent');
            } else {
                setBackgroundColor('transparent');
            }
        };

        checkAndSetBackgroundColor();
    }, [location]);

    const showButton = () => {
        if (window.innerWidth <= 1200) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    const toggleDropdown = () => {
        if (window.innerWidth <= 1200) {
            setDropdown(true);
        }
    };

    const handleHover = () => {
        if (window.innerWidth > 1200) {
            setDropdown(true);
        }
    };

    const handleHoverLeave = () => {
        if (window.innerWidth > 1200) {
            setDropdown(false);
        }
    };

    const handlePropertyChevronClick = () => {
        setPropertyChevronClicked(!propertyChevronClicked);
    };

    const handleAboutChevronClick = () => {
        setAboutChevronClicked(!aboutChevronClicked);
    };

    const handleSellingChevronClick = () => {
        setSellingChevronClicked(!sellingChevronClicked);
    };

    const handleBuyingChevronClick = () => {
        setBuyingChevronClicked(!buyingChevronClicked);
    };

    useEffect(() => {
        showButton();
        const handleResize = () => showButton();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const changeNavbarBackground = () => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            navbar.style.backgroundColor = window.scrollY >= 20 ? 'rgba(37,39,79,1)' : 'transparent';
        }
    };

    useEffect(() => {
        showButton();
        const handleResize = () => showButton();
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', changeNavbarBackground);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', changeNavbarBackground);
        };
    }, []);

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className="navbar" style={{ backgroundColor }}>
                    <div className="navbar-container container">
                        <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                            <img src="/white-side-by-side-logo.png" alt="Nevin Real Estate" className="logo-img" />
                        </Link>
                        <div className="outer-menu-div">
                            <ul className={window.innerWidth < 1100 ? "small-screen" : "nav-outer-menu"}>
                                <li className="outer-nav-item"
                                    onClick={toggleDropdown}
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleHoverLeave}>
                                    <span className="outer-nav-links">
                                        Properties <FaChevronDown className={dropdown ? "fa-chevron-outer-rotate" : "fa-chevron-outer"} />
                                    </span>
                                    {dropdown && (
                                        <div className="dropdown-menu active" style={{ backgroundColor: window.scrollY >= 20 ? 'rgba(37,39,79,0.7)' : 'transparent' }}>
                                            <Link to="/featured-listings" className="dropdown-link" onClick={closeMobileMenu}>Featured Listings</Link>
                                            <Link to="/sold-listings" className="dropdown-link" onClick={closeMobileMenu}>Luxury Sales</Link>
                                        </div>
                                    )}
                                </li>
                                <li className="outer-nav-item">
                                    <Link to="https://nevinraghuveer.results.net/real-estate" className="outer-nav-links">Home Search</Link>
                                </li>
                                <li className="outer-nav-item">
                                    <Link to="/contact" className="outer-nav-links">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="menu-icon" onClick={handleClick}>
                            <FaBars />
                        </div>
                        <ul className={click ? "nav-hamburger-menu active" : "nav-hamburger-menu"}>
                            <li className="close-menu" onClick={closeMobileMenu}>Close Menu<FaTimes className="fa-times" /></li>
                            <li className="nav-item">
                                <Link to='/' className="nav-links" onClick={closeMobileMenu}>
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item"
                                onClick={handlePropertyChevronClick}>
                                <span className="nav-links">
                                    Properties <FaChevronDown className={propertyChevronClicked ? "fa-chevron-rotate" : "fa-chevron"} />
                                </span>
                            </li>
                            {propertyChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/featured-listings" className="nav-links-properties" onClick={closeMobileMenu}>
                                        Featured Listings
                                    </Link>
                                </li>
                            )}
                            {propertyChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/sold-listings" className="nav-links-properties" onClick={closeMobileMenu}>
                                        Luxury Sales
                                    </Link>
                                </li>
                            )}
                            <li className="nav-item">
                                <Link to="https://nevinraghuveer.results.net/real-estate" className="nav-links" onClick={closeMobileMenu}>Home Search</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>Contact Us</Link>
                            </li>
                            <li className="nav-item"
                                onClick={handleAboutChevronClick}>
                                <span className="nav-links">
                                    About Us <FaChevronDown className={aboutChevronClicked ? "fa-chevron-rotate" : "fa-chevron"} />
                                </span>
                            </li>
                            {aboutChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/meet-the-team" className="nav-links-about" onClick={closeMobileMenu}>
                                        Meet the Team
                                    </Link>
                                </li>
                            )}
                            {aboutChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/about-nevin" className="nav-links-about" onClick={closeMobileMenu}>
                                        About Nevin
                                    </Link>
                                </li>
                            )}
                            {aboutChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/awards" className="nav-links-about" onClick={closeMobileMenu}>
                                        Awards
                                    </Link>
                                </li>
                            )}
                            {aboutChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/testimonials" className="nav-links-about" onClick={closeMobileMenu}>
                                        Testimonials
                                    </Link>
                                </li>
                            )}
                            <li className="nav-item"
                                onClick={handleSellingChevronClick}>
                                <span className="nav-links">
                                    Selling A Home <FaChevronDown className={sellingChevronClicked ? "fa-chevron-rotate" : "fa-chevron"} />
                                </span>
                            </li>
                            {/* {sellingChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/selling-with-nevin" className="nav-links-selling" onClick={closeMobileMenu}>
                                        Sell With Nevin
                                    </Link>
                                </li>
                            )} */}
                            {sellingChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/seller-questionnaire" className="nav-links-selling" onClick={closeMobileMenu}>
                                        Home Seller Questionnaire
                                    </Link>
                                </li>
                            )}
                            {sellingChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/about-selling" className="nav-links-selling" onClick={closeMobileMenu}>
                                        Home Seller's Guide
                                    </Link>
                                </li>
                            )}
                            <li className="nav-item"
                                onClick={handleBuyingChevronClick}>
                                <span className="nav-links">
                                    Buying A Home <FaChevronDown className={buyingChevronClicked ? "fa-chevron-rotate" : "fa-chevron"} />
                                </span>
                            </li>
                            {/* {buyingChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/buying-with-nevin" className="nav-links-selling" onClick={closeMobileMenu}>
                                        Buy With Nevin
                                    </Link>
                                </li>
                            )} */}
                            {buyingChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/buyer-questionnaire" className="nav-links-selling" onClick={closeMobileMenu}>
                                        Home Buyer Questionnaire
                                    </Link>
                                </li>
                            )}
                            {buyingChevronClicked && (
                                <li className="nav-item-sub">
                                    <Link to="/about-buying" className="nav-links-selling" onClick={closeMobileMenu}>
                                        Home Buyer's Guide
                                    </Link>
                                </li>
                            )}
                            {/* <li className="nav-item">
                                <Link to="/neighborhoods" className="nav-links" onClick={closeMobileMenu}>Neighborhoods</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to="https://consumer.hifello.com/lp/66284772d36aac002e28adf7
" className="nav-links" onClick={closeMobileMenu}>Home Valuation</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="https://consumer.hifello.com/lp/66284772d36aac002e28adf7
" className="nav-links" onClick={closeMobileMenu}>Cash Offer Program</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/mortgage-calculator" className="nav-links" onClick={closeMobileMenu}>Mortgage Calculator</Link>
                            </li>
                            <li className="dropdown-social-links">
                                <Link to="http://www.facebook.com/nevinrealestate" className="dropdown-social-link" onClick={closeMobileMenu}>
                                    <FaFacebook className="dropdown-social-icon" />
                                </Link>
                                <Link to="http://www.instagram.com/nevinrealestate" className="dropdown-social-link" onClick={closeMobileMenu}>
                                    <FaInstagram className="dropdown-social-icon" />
                                </Link>
                                <Link to="https://www.linkedin.com/in/nevinraghuveer" className="dropdown-social-link" onClick={closeMobileMenu}>
                                    <FaLinkedin className="dropdown-social-icon" />
                                </Link>
                                <Link to="/contact-form" className="dropdown-social-link" onClick={closeMobileMenu}>
                                    <FaEnvelope className="dropdown-social-icon" />
                                </Link>
                                <a href="tel:952-848-2475" className="dropdown-social-link" onClick={closeMobileMenu}>
                                    <FaPhone className="dropdown-social-icon" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
