import React from 'react';
import "../NewsletterSignUp/NSUForm.css";

function NSUForm() {
    return (
        <div>
            <div className="nsu-form-div">
                <iframe src="https://interfaces.zapier.com/embed/page/clxyycouf0019fhgt3vo3lmzi?" className="nsu-form"></iframe>
            </div>
        </div>
    )
}

export default NSUForm
