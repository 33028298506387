import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaEnvelope } from 'react-icons/fa';
import '../HomeAbout/HomeAbout.css';

function HomeAbout() {
    // const homeAboutAnimations = () => {
    //     const homeAboutSection = document.querySelector('.home-about-container');
    //     const homeAboutImgWrapper = document.querySelector('.home-about-img-wrapper');
    //     const homeAboutTextDiv = document.querySelector('.home-about-text-div');
    //     const windowHeight = window.innerHeight;
    //     const scrollY = window.scrollY;
    //     const homeAboutSectionTop = homeAboutSection.getBoundingClientRect().top;

    //     if (scrollY + windowHeight > homeAboutSectionTop + 200) {
    //         homeAboutImgWrapper.classList.add('slide-in');
    //     } else {
    //         homeAboutImgWrapper.classList.remove('slide-in');
    //     }

    //     if (scrollY + windowHeight > homeAboutSectionTop + 100) {
    //         homeAboutTextDiv.classList.add('slide-down');
    //     } else {
    //         homeAboutTextDiv.classList.remove('slide-down');
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', homeAboutAnimations);

    //     return () => {
    //         window.removeEventListener('scroll', homeAboutAnimations);
    //     };
    // }, []);

    const handleCall = () => {
        window.location.href = "tel: +19528482475";
    };

    const handleEmail = () => {
        window.location.href = "mailto:nevin@nevinrealestate.com";
    };

    const handleECall = () => {
        window.location.href = "tel: +19528482578";
    };

    const handleEEmail = () => {
        window.location.href = "mailto:elizabeth@nevinrealestate.com";
    };

    const handleCCall = () => {
        window.location.href = "tel: +19528482538";
    };

    const handleCEmail = () => {
        window.location.href = "mailto:cassandra@nevinrealestate.com";
    };

    const [selected, setSelected] = useState("nevin");

    const handleClick = (picture) => {
        setSelected(picture);
    };

    return (
        <div className="home-about-main-container">
            <div className="ha-mtt">
                <p className="home-about-h1">Meet the Team</p>
                <div className="ha-mtt-people-div">
                    <div className="ha-mtt-person-div">
                        <Link to="/about-nevin" className="ha-mtt-link">
                            <img src="/nevin-headshot-transparent-circle.png" className="ha-mtt-circle-img" />
                        </Link>
                        <Link to="/about-nevin" className="ha-mtt-link">Nevin Raghuveer</Link>
                    </div>
                    <div className="ha-mtt-person-div">
                        <Link to="/about-elizabeth" className="ha-mtt-link">
                            <img src="/elizabeth-headshot-transparent-circle.png" className="ha-mtt-circle-img" />
                        </Link>
                        <Link to="/about-elizabeth" className="ha-mtt-link">Elizabeth Mullen</Link>
                    </div>
                    <div className="ha-mtt-person-div">
                        <Link to="/about-cassandra" className="ha-mtt-link">
                            <img src="/cassandra-headshot-transparent-circle.png" className="ha-mtt-circle-img" />
                        </Link>
                        <Link to="/about-cassandra" className="ha-mtt-link">Cassandra Bryan</Link>
                    </div>
                </div>
            </div>

            {/* {selected === "nevin" && <>
                <div className="ha-person-container" style={{ display: selected === "nevin" ? 'flex' : 'none' }}>
                    <div className="home-about-container">
                        <div className="home-about-img-wrapper">
                            <img src="/nevin.png" className="home-about-img" alt="Nevin Raghuveer" />
                            <div className="han-more-info">
                                <Link to="/about-nevin" className="han-more-info-link">More Information</Link>
                            </div>
                        </div>
                        <div className="home-about-text-div">
                            <p className="home-about-h1">MEET NEVIN</p>
                            <h3 className="home-about-h3">REALTOR &#174; | #1 RE/MAX RESULTS AGENT</h3>
                            <p className="home-about-p">
                                Nevin entered the real estate industry after establishing himself in the small business world, running successful businesses and learning the value of smart investment and decision making. His business experience gives him a unique edge among his competition. Whether he's at the negotiating table or researching the latest pricing trends, Nevin is able to put his years of experience to work managing your housing investment. Combined, Nevin and his team have 50 + years of real estate experience and over 1000 homes sold.
                            </p>
                            <div className="home-about-contact-info">
                                <div className="home-about-contact-info-phone-license">
                                    <div className="hacipl-left">
                                        <p className="home-about-label">
                                            Primary Phone
                                        </p>
                                        <p className="home-about-contact-info-text">
                                            (952) 848-2475
                                        </p>
                                    </div>
                                    <div className="hacipl-right">
                                        <p className="home-about-label">
                                            License Number
                                        </p>
                                        <p className="home-about-contact-info-text">
                                            00000000
                                        </p>
                                    </div>
                                </div>
                                <p className="home-about-label">Email</p>
                                <p className="home-about-contact-info-text">
                                    nevin@nevinrealestate.com
                                </p>
                                <div className="home-about-social">
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.facebook.com/nevinrealestate" className="home-about-social-link">
                                            <FaFacebook className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.instagram.com/nevinrealestate" className="home-about-social-link">
                                            <FaInstagram className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.linkedin.com" className="home-about-social-link">
                                            <FaLinkedin className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper" onClick={handleCall}>
                                        <FaPhone className="home-about-social-icon" />
                                    </div>
                                    <div className="home-about-social-wrapper" onClick={handleEmail}>
                                        <FaEnvelope className="home-about-social-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ha-mtt">
                        <p className="home-about-h1">Meet the Team</p>
                        <div className="ha-mtt-people-div">
                            <div className="ha-mtt-person-div">
                                <img src="/elizabeth-headshot-transparent-circle.png" className="ha-mtt-circle-img" onClick={() => handleClick("elizabeth")} />
                                <p className="home-about-h3" onClick={() => handleClick("elizabeth")}>Elizabeth Mullen</p>
                            </div>
                            <div className="ha-mtt-person-div">
                                <img src="/cassandra-headshot-transparent-circle.png" className="ha-mtt-circle-img" onClick={() => handleClick("cassandra")} />
                                <p className="home-about-h3" onClick={() => handleClick("cassandra")}>Cassandra Bryan</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {selected === "elizabeth" && <>
                <div className="ha-person-container" style={{ display: selected === "elizabeth" ? 'flex' : 'none' }}>
                    <div className="home-about-container">
                        <div className="home-about-img-wrapper">
                            <img src="/elizabeth.png" className="home-about-img" alt="Elizabeth Mullen" />
                            <div className="han-more-info">
                                <Link to="/about-elizabeth" className="han-more-info-link">More Information</Link>
                            </div>
                        </div>
                        <div className="home-about-text-div">
                            <p className="home-about-h1">MEET ELIZABETH</p>
                            <h3 className="home-about-h3">REALTOR &#174; | OPERATIONS MANAGER</h3>
                            <p className="home-about-p">
                                With over 15 years of experience in real estate, Elizabeth brings a wealth of knowledge and insight to the team. She specializes in helping seniors and their families through the downsizing process, easing the transition and maximizing financial benefit. In partnership with the Nokomis Square senior co-op, Elizabeth provides a valuable resource to residents, prospective residents, and their families. Elizabeth also spearheads project management on behalf of our team, working with sellers to prepare their homes for listing and ensuring the home and the listing are optimized to secure the best deal possible.
                            </p>
                            <div className="home-about-contact-info">
                                <div className="home-about-contact-info-phone-license">
                                    <div className="hacipl-left">
                                        <p className="home-about-label">
                                            Primary Phone
                                        </p>
                                        <p className="home-about-contact-info-text">
                                            (952) 848-2578
                                        </p>
                                    </div>
                                    <div className="hacipl-right">
                                        <p className="home-about-label">
                                            License Number
                                        </p>
                                        <p className="home-about-contact-info-text">
                                            00000000
                                        </p>
                                    </div>
                                </div>
                                <p className="home-about-label">Email</p>
                                <p className="home-about-contact-info-text">
                                    elizabeth@nevinrealestate.com
                                </p>
                                <div className="home-about-social">
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.facebook.com/nevinrealestate" className="home-about-social-link">
                                            <FaFacebook className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.instagram.com/nevinrealestate" className="home-about-social-link">
                                            <FaInstagram className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.linkedin.com" className="home-about-social-link">
                                            <FaLinkedin className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper" onClick={handleCall}>
                                        <FaPhone className="home-about-social-icon" />
                                    </div>
                                    <div className="home-about-social-wrapper" onClick={handleEmail}>
                                        <FaEnvelope className="home-about-social-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ha-mtt">
                        <p className="home-about-h1">Meet the Team</p>
                        <div className="ha-mtt-people-div">
                            <div className="ha-mtt-person-div">
                                <img src="/nevin-headshot-transparent-circle.png" className="ha-mtt-circle-img" onClick={() => handleClick("nevin")} />
                                <p className="home-about-h3" onClick={() => handleClick("nevin")}>Nevin Raghuveer</p>
                            </div>
                            <div className="ha-mtt-person-div">
                                <img src="/cassandra-headshot-transparent-circle.png" className="ha-mtt-circle-img" onClick={() => handleClick("cassandra")} />
                                <p className="home-about-h3" onClick={() => handleClick("cassandra")}>Cassandra Bryan</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {selected === "cassandra" && <>
                <div className="ha-person-container" style={{ display: selected === "cassandra" ? 'flex' : 'none' }}>
                    <div className="home-about-container">
                        <div className="home-about-img-wrapper">
                            <img src="/cassandra-headshot-transparent.png" className="home-about-img" alt="Cassandra Bryan" />
                            <div className="han-more-info">
                                <Link to="/about-cassandra" className="han-more-info-link">More Information</Link>
                            </div>
                        </div>
                        <div className="home-about-text-div">
                            <p className="home-about-h1">MEET CASSANDRA</p>
                            <h3 className="home-about-h3">REALTOR &#174; | MARKETING & TRANSACTIONS MANAGER</h3>
                            <p className="home-about-p">
                                Cassandra has been a licensed realtor since 2021. With a keen eye for data, detail, and design, she brings a fresh perspective and dynamic, tech-driven approach to the Nevin Real Estate team. Prior to obtaining her license, she held key roles in real estate administration, focusing on compliance, training, and portfolio management. Her comprehensive understanding of the market's operational and regulatory aspects enables her to deliver quality service tailored to each client's needs.
                            </p>
                            <div className="home-about-contact-info">
                                <div className="home-about-contact-info-phone-license">
                                    <div className="hacipl-left">
                                        <p className="home-about-label">
                                            Primary Phone
                                        </p>
                                        <p className="home-about-contact-info-text">
                                            (952) 848-2538
                                        </p>
                                    </div>
                                    <div className="hacipl-right">
                                        <p className="home-about-label">
                                            License Number
                                        </p>
                                        <p className="home-about-contact-info-text">
                                            40745500
                                        </p>
                                    </div>
                                </div>
                                <p className="home-about-label">Email</p>
                                <p className="home-about-contact-info-text">
                                    cassandra@nevinrealestate.com
                                </p>
                                <div className="home-about-social">
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.facebook.com/nevinrealestate" className="home-about-social-link">
                                            <FaFacebook className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.instagram.com/nevinrealestate" className="home-about-social-link">
                                            <FaInstagram className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper">
                                        <Link to="http://www.linkedin.com" className="home-about-social-link">
                                            <FaLinkedin className="home-about-social-icon" />
                                        </Link>
                                    </div>
                                    <div className="home-about-social-wrapper" onClick={handleCall}>
                                        <FaPhone className="home-about-social-icon" />
                                    </div>
                                    <div className="home-about-social-wrapper" onClick={handleEmail}>
                                        <FaEnvelope className="home-about-social-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ha-mtt">
                        <p className="home-about-h1">Meet the Team</p>
                        <div className="ha-mtt-people-div">
                            <div className="ha-mtt-person-div">
                                <img src="/nevin-headshot-transparent-circle.png" className="ha-mtt-circle-img" onClick={() => handleClick("nevin")} />
                                <p className="home-about-h3" onClick={() => handleClick("nevin")}>Nevin Raghuveer</p>
                            </div>
                            <div className="ha-mtt-person-div">
                                <img src="/elizabeth-headshot-transparent-circle.png" className="ha-mtt-circle-img" onClick={() => handleClick("elizabeth")} />
                                <p className="home-about-h3" onClick={() => handleClick("elizabeth")}>Elizabeth Mullen</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            } */}
        </div>
    )
}

export default HomeAbout