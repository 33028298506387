import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaPhone, FaEnvelope } from 'react-icons/fa';
import "../Footer/Footer.css";

function Footer() {
    const handleCall = () => {
        window.location.href = "tel: +19528482475";
    };

    const handleEmail = () => {
        window.location.href = "mailto:nreteam@nevinrealestate.com";
    };

    return (
        <div className="footer-container">
            <div className="footer-logo-wrapper">
                <img src="/black-logo.png" className="footer-logo" alt="Nevin Real Estate Logo" />
            </div>
            <div className="footer-site-link-div">
                <Link to="/" className="footer-link">Home</Link>
                <Link to="/meet-the-team" className="footer-link">Meet the Team</Link>
                <Link to="/featured-listings" className="footer-link">Listings</Link>
                <Link to="https://nevinraghuveer.results.net/real-estate" className="footer-link">Home Search</Link>
                <Link to="/sell-with-nevin" className="footer-link">Sellers</Link>
                <Link to="/buy-with-nevin" className="footer-link">Buyers</Link>
                <Link to="/contact" className="footer-link">Contact</Link>
            </div>
            <div className="footer-contact-address-div">
                <div className="footer-contact-div">
                    <p className="footer-label">Contact</p>
                    <p className="footer-info-bold">Nevin Raghuveer</p>
                    <p className="footer-info">(952) 848-2475</p>
                    <p className="footer-info email-full">NRETeam@NevinRealEstate.com</p>
                    <p className="footer-info email-p-t">NRETeam@Nevin</p>
                    <p className="footer-info email-p-b">RealEstate.com</p>
                </div>
                <div className="footer-address-div">
                    <p className="footer-label">Address</p>
                    <p className="footer-info">3535 Plymouth Boulevard, Suite 210</p>
                    <p className="footer-info">Plymouth, MN 55447</p>
                </div>
            </div>
            <div className="footer-social-links-div">
                <p className="footer-label">Let's Connect</p>
                <div className="footer-social-links">
                    <div className="footer-social-wrapper">
                        <Link to="http://www.facebook.com/nevinrealestate" className="footer-social-link">
                            <FaFacebook className="footer-social-icon" />
                        </Link>
                    </div>
                    <div className="footer-social-wrapper">
                        <Link to="http://www.instagram.com/nevinrealestate" className="footer-social-link">
                            <FaInstagram className="footer-social-icon" />
                        </Link>
                    </div>
                    <div className="footer-social-wrapper" onClick={handleCall}>
                        <FaPhone className="footer-social-icon" />
                    </div>
                    <div className="footer-social-wrapper" onClick={handleEmail}>
                        <FaEnvelope className="footer-social-icon" />
                    </div>
                </div>
            </div>
            <div className="footer-legal-and-credentials-div">
                <div className="footer-credentials-div">
                    <div className="footer-compliance-logos-div">
                        <img src="/compliance-logos.png" className="footer-compliance-logos" alt="REALTOR, MLS, and Equal Housing Logos" />
                    </div>
                </div>
                <div className="footer-legal-div">
                    <p className="footer-legal-p">&copy; 2024 Nevin Real Estate. | <Link to="/privacy" className="privacy-link">Privacy Policy</Link></p>
                    <p className="footer-legal-p">All information is deemed reliable but not guaranteed and should be independently reviewed and verified.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
