import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { VictoryPie, VictoryLabel } from 'victory';
import { FaCircle } from 'react-icons/fa';
import "../MortgageCalculator/MortgageCalculator.css";
import Footer from '../../components/Footer/Footer';
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';

function MortgageCalculator() {
    const [propertyPrice, setPropertyPrice] = useState(500000);
    const [downPaymentAmount, setDownPaymentAmount] = useState(50000);
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(10);
    const [interestRate, setInterestRate] = useState(7.25);
    const [loanTerm, setLoanTerm] = useState(30);
    const [propertyTaxes, setPropertyTaxes] = useState(0);
    const [hoaDues, setHoaDues] = useState(0);
    const [homeownersInsurance, setHomeownersInsurance] = useState(0);
    const [principal, setPrincipal] = useState(0);
    const [totalMonthlyPayment, setTotalMonthlyPayment] = useState(0);

    useEffect(() => {
        const downPaymentPercentageFromAmount = (downPaymentAmount / propertyPrice) * 100;
        setDownPaymentPercentage(downPaymentPercentageFromAmount);

        const downPayment = (propertyPrice * downPaymentPercentage) / 100;
        const loanAmount = propertyPrice - downPayment;
        const annualInterestRate = interestRate / 100;
        const monthlyInterestRate = annualInterestRate / 12;
        const numberOfPayments = loanTerm * 12;
        const calculatedPrincipal = Math.round(loanAmount * monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments)));
        setPrincipal(calculatedPrincipal);

        const totalMonthlyPayment = Math.round(calculatedPrincipal + homeownersInsurance / 12 + Math.round(propertyTaxes / 12) + hoaDues);
        setTotalMonthlyPayment(totalMonthlyPayment);
    }, [propertyPrice, downPaymentAmount, downPaymentPercentage, interestRate, loanTerm, propertyTaxes, hoaDues, homeownersInsurance]);

    const handleDownPaymentAmountChange = (e) => {
        const amount = parseFloat(e.target.value);
        setDownPaymentAmount(amount);
    };

    const handleDownPaymentPercentageChange = (e) => {
        const percentage = parseFloat(e.target.value);
        setDownPaymentPercentage(percentage);
        setDownPaymentAmount((propertyPrice * percentage) / 100);
    };

    const data = [
        { x: 'Principal & Interest', y: principal },
        { x: 'Homeowners Insurance', y: homeownersInsurance / 12 },
        { x: 'Property Taxes', y: Math.round(propertyTaxes / 12) },
        { x: 'HOA Dues', y: hoaDues },
    ];

    const COLORS = ['#243852', '#355682', '#357d82', '#458e77'];

    const MortgagePieChart = () => (
        <VictoryPie
            data={data}
            colorScale={COLORS}
            innerRadius={100}
            labelRadius={120}
            style={{ labels: { fontSize: 12, fill: 'white' } }}
            x="x"
            y="y"
            labelComponent={
                <VictoryLabel
                    angle={0}
                    style={{ fontSize: 12, fill: 'white' }}
                    renderInPortal
                    text={({ datum }) => (datum.y > 0 ? datum.x : '')}
                />
            }
        />
    );

    return (
        <>
            <NavbarBlack />
            <div className="mc-page-container">
                <h1 className="mc-page-headline-h1 mc-headline-full">Mortgage Calculator</h1>
                <h1 className="mc-page-headline-h1 mc-headline-p-t">Mortgage</h1>
                <h1 className="mc-page-headline-h1 mc-headline-p-b">Calculator</h1>
                <p className="mc-page-intro">
                    Use our calculator tool to estimate total monthly mortgage payment.
                </p>
                <p className="mc-page-intro">
                    Knowing how much purchasing power you have can help take some of the stress out of the homebuying process, and it helps us streamline the search for your next home.
                </p>
                <p className="mc-page-intro">
                    This is an estimate only. For more information, if you have any questions, or if you would like a referral to one of our trusted mortgage lenders, <Link to="/contact" className="mc-contact-link">contact Nevin Real Estate</Link>.
                </p>
                <div className="calculator-container">
                    <div className="mc-cc-left">
                        <div className="mc-entry-container">
                            <p className="mc-label">Purchase Price</p>
                            <input
                                type="number"
                                value={propertyPrice}
                                className="mc-input"
                                placeholder="500000"
                                onChange={(e) => setPropertyPrice(parseFloat(e.target.value))}
                                onFocus={() => {
                                    if (propertyPrice === 500000) {
                                        setPropertyPrice('');
                                    }
                                }}
                            />
                        </div>
                        <p className="mc-label-down">
                            Down Payment
                        </p>
                        <div className="mc-entry-container-down">
                            <div className="mc-down-left">
                                <p className="mc-label">
                                    Amount
                                </p>
                                <input
                                    type="number"
                                    value={downPaymentAmount}
                                    className="mc-input"
                                    placeholder="50000"
                                    onChange={handleDownPaymentAmountChange}
                                    onFocus={() => {
                                        if (downPaymentAmount === 50000) {
                                            setDownPaymentAmount('');
                                        }
                                    }}
                                />
                            </div>
                            <div className="mc-down-right">
                                <p className="mc-label">Percentage
                                </p>
                                <input
                                    type="number"
                                    value={downPaymentPercentage}
                                    className="mc-input"
                                    placeholder="10"
                                    onChange={handleDownPaymentPercentageChange}
                                    onFocus={() => {
                                        if (downPaymentPercentage === 10) {
                                            setDownPaymentPercentage('');
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mc-entry-container">
                            <p className="mc-label">Interest Rate</p>
                            <input
                                type="number"
                                value={interestRate}
                                className="mc-input"
                                placeholder="7.25%"
                                onChange={(e) => setInterestRate(parseFloat(e.target.value))}
                                onFocus={() => {
                                    if (interestRate === 7.25) {
                                        setInterestRate('');
                                    }
                                }}
                            />
                        </div>
                        <div className="mc-entry-container">
                            <p className="mc-label">Loan Term <span className="mc-label-i">(If not 30 years)</span></p>
                            <select
                                value={loanTerm}
                                className="mc-select"
                                onChange={(e) => setLoanTerm(parseInt(e.target.value))}
                            >
                                <option value={20} selected={loanTerm === 20}>
                                    20 years
                                </option>
                                <option value={15} selected={loanTerm === 15}>
                                    15 years
                                </option>
                                <option value={10} selected={loanTerm === 10}>
                                    10 years
                                </option>
                            </select>
                        </div>
                        <div className="mc-entry-container">
                            <p className="mc-label">Property Taxes <span className="mc-label-i">(Annually)</span></p>
                            <input
                                type="number"
                                value={propertyTaxes}
                                className="mc-input"
                                placeholder="0"
                                onChange={(e) => setPropertyTaxes(parseFloat(e.target.value))}
                                onFocus={() => {
                                    if (propertyTaxes === 0) {
                                        setPropertyTaxes('');
                                    }
                                }}
                            />
                        </div>
                        <div className="mc-entry-container">
                            <p className="mc-label">HOA Dues <span className="mc-label-i">(Monthly)</span></p>
                            <input
                                type="number"
                                value={hoaDues}
                                className="mc-input"
                                placeholder="0"
                                onChange={(e) => setHoaDues(parseFloat(e.target.value))}
                                onFocus={() => {
                                    if (hoaDues === 0) {
                                        setHoaDues('');
                                    }
                                }}
                            />
                        </div>
                        <div className="mc-entry-container">
                            <p className="mc-label">Homeowners Insurance <span className="mc-label-i">(Annually)</span></p>
                            <input
                                type="number"
                                value={homeownersInsurance}
                                className="mc-input"
                                placeholder="0"
                                onChange={(e) => setHomeownersInsurance(parseFloat(e.target.value))}
                                onFocus={() => {
                                    if (homeownersInsurance === 0) {
                                        setHomeownersInsurance('');
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="mc-cc-right">
                        <p className="mc-your-pmt">Your Payment:</p>
                        <p className="mc-pmt-amt">${isNaN(totalMonthlyPayment) ? '0' : totalMonthlyPayment.toLocaleString()}/mo</p>
                        <div className="mc-pie-chart-container">
                            <MortgagePieChart className="mc-pie-chart" />
                        </div>
                        <div className="mc-pie-chart-legend">
                            <p className="mc-pie-chart-legend-p"><FaCircle className="mc-fa-circle" style={{ color: '#243852' }} />Principal and Interest: <span className="mc-results-bold">${isNaN(principal) ? '0' : principal.toLocaleString()}</span></p>
                            <p className="mc-pie-chart-legend-p"><FaCircle className="mc-fa-circle" style={{ color: '#999999' }} />Homeowners Insurance: <span className="mc-results-bold">${isNaN(homeownersInsurance) ? '0' : (homeownersInsurance / 12).toLocaleString()}</span></p>
                            <p className="mc-pie-chart-legend-p"><FaCircle className="mc-fa-circle" style={{ color: '#333333' }} />Property Taxes: <span className="mc-results-bold">${isNaN(propertyTaxes) ? '0' : (propertyTaxes / 12).toLocaleString()}</span></p>
                            <p className="mc-pie-chart-legend-p"><FaCircle className="mc-fa-circle" style={{ color: '#FF6384' }} />HOA Dues: <span className="mc-results-bold">${isNaN(hoaDues) ? '0' : (hoaDues / 12).toLocaleString()}</span></p>
                        </div>
                    </div>
                </div>
                <p className="mc-page-cta">
                    Ready to start your home buying journey? Fill out our Buyer Questionnaire to get started.
                </p>
                <div className="mc-button-container">
                    <Link to="/buyer-questionnaire" className="mc-button">Buyer Questionnaire</Link>
                </div>
            </div>
            <TestimonialsComponent />
            <div className="mc-parallax">
                <div className="mc-parallax-content">
                    <h1 className="mc-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="mc-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MortgageCalculator