export const testimonials = [
    {
        name: "lynnemcwing68",
        date: "December 15, 2023",
        site: "Zillow",
        testimonial: "Nevin and team helped us manage through what currently is a frustrating and confusing housing market. They offered up to date market data, helped us get home ready to show and supported us through a new and challenging market. Advice was clear data driven and resulted in a sale that worked for all. Highly recommend."
    },
    {
        name: "Sandeepan Dutta",
        date: "December 2023",
        site: "Google",
        testimonial: "Nevin and his team helped me and my wife buy a home. Nevin is simply the best in this business and worked with us as our friend while being professional, trust-worthy, detail-oriented, patient, and looking out for our best interests. They understood our expectations, explained the process, presented a strategy per market trend in the location we wanted, and finally got us our dream home! The team also supported us through the closing, provided honest opinions and contacts for house updates. Thank you Nevin. You and your team made buying a house easy and have exceeded all our expectations."
    },
    {
        name: "whitneygartland",
        date: "December 5, 2023",
        site: "Zillow",
        testimonial: "My husband and I had the pleasure of working with Nevin and his team for our new home purchase (and prior home sale). The entire team provided exceptional service and navigated us through the complexities of the process with agility and expertise."
    },
    {
        name: "Brian J",
        date: "December 2023",
        site: "Google",
        testimonial: "I was referred to Nevin several years ago and sought his advice many times. I learned quickly he takes great care of his clients. We stayed in touch and eventually he represented me on the purchase of a new home with a national builder. His knowledge of new construction and market conditions combined with his negotiation and representation skills proved to be outstanding as we secured a great deal in a tough market. When we moved out of state several years later, we called Nevin to list our home and were the benefactors of a smart purchase. The selling process was smooth and managed to precision. Nevin's team provided staging and home preparation amongst many other items.  Once the transaction was complete, Nevin even referred us to a realtor out of state that assisted in our purchase. I trust Nevin completely! His entire team is exceptional. You can be certain he will be honest and provide you with a fantastic outcome. I highly recommend him for any real estate transaction!"
    },
    {
        name: "C Wan",
        date: "December 2023",
        site: "Google",
        testimonial: "Nevin and his team are great! I had Nevin sell my house last year. After the first meeting, his professionalism and knowledge of the business made us feel confident working with him. Nevin's team helped a lot. Elizabeth came to our house for a staging consultation and provided us with advice on the dos and don'ts. It even helped us find painters to paint the interior of the house in a timely manner. Nevin listens to his clients and certainly helps to find solutions. Our house sold quickly, and the selling and closing process went smoothly. This year, we called Nevin again to help us find a new home. He always personally showed me the houses that I was interested in and discussed with me the property's pros and cons based on our needs. I finally found a house with a satisfying price! I had very good selling and buying house experiences with the Nevin Real Estate team. Everyone on the team is nice, helpful, and knowledgeable. Highly recommended!"
    },
    {
        name: "Gina Brinkhaus",
        date: "December 1, 2023",
        site: "Zillow",
        testimonial: "Nevin and his team are TOP KNOTCH. Nevin's intelligence and ability to think outside the box made all the difference in getting our house sold quickly in a slowing market (and for top dollar!) When searching for our new home, Nevin did not hesitate to point out reasons why a particular house or location might not be the best fit for our family; that patience and honesty led us to purchase the RIGHT home in the perfect neighoborhood and we are so incredibly thankful for that. Elizabeth was wonderful throughout the buying and selling process as well. She is an absolute pro at navigating the unique and unexpected twists & turns that come with buying/selling a home and her communication and professionalism are second to none. We could not be any happier with the experience we had working with Nevin and his team. Highly recommend!"
    },
    {
        name: "Renee Rach",
        date: "November 2023",
        site: "Google",
        testimonial: "We have worked with Nevin buying and selling three different houses. Each time was a wonderful experience. He has exceptional knowledge of the real estate market and is an extremely caring and intelligent person. I cannot stress enough how positive all the experiences were. We've worked with him for over 20 years and will continue to call him anytime we decide to buy/sell a home. Plus, just an all-around nice guy. Highly recommend."
    },
    {
        name: "rhnguyen76",
        date: "November 24, 2023",
        site: "Zillow",
        testimonial: "Nevin and his team were the best to work with. They had my family's concerns and demands in mind every step of the way. They were very in tune with the housing market which benefited us when we sold our house and then again when we bought our new house. They are my new realtor team from here on out. Thank you Nevin and your team."
    }
]