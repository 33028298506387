import React, { useState, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import "../Selling/AboutSelling.css";
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import Footer from '../../components/Footer/Footer';
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';

function AboutSelling() {
    const [slideIndex, setSlideIndex] = useState(1);
    let slideTimer;

    useEffect(() => {
        showSlides(slideIndex);
        startSlideTimer();
        return () => clearTimeout(slideTimer);
    }, [slideIndex]);

    function plusSlides(n) {
        setSlideIndex(prevIndex => prevIndex + n);
        resetSlideTimer();
    };

    function showSlides(n) {
        const slides = document.getElementsByClassName("as-testimonial-slide");
        let i;
        if (slides.length === 0) return;

        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "block";
    }

    function startSlideTimer() {
        slideTimer = setTimeout(() => {
            plusSlides(1);
        }, 7000);
    };

    function resetSlideTimer() {
        clearTimeout(slideTimer);
        startSlideTimer();
    };

    const [abAVisible, setAbAVisible] = useState(Array(10).fill(false));

    const questionsAndAnswers = [
        {
            question: "What can I expect when selling my home?",
            answer: "When preparing to sell your home, the first step is to meet with the NRE team, your trusted real estate advisors, to discuss the selling timeline and strategize the best approach for your specific situation. During this initial consultation, we'll assess your needs and objectives, provide a comparative market analysis to determine the optimal listing price, and outline a comprehensive marketing plan to maximize exposure and attract potential buyers. Once you're ready to list, we'll work with you to prepare your home for sale, offering guidance on staging, repairs, and enhancements to showcase its full potential. From professional photography and virtual tours to targeted advertising and open houses, the NRE team will leverage our expertise and resources to ensure your property stands out in the market. Throughout the selling process, we'll handle all negotiations, paperwork, and logistics with precision and professionalism, guiding you every step of the way until the successful closing of your sale. With the NRE team by your side, selling your home is not just a transaction; it's a seamless and rewarding experience from start to finish."
        },
        {
            question: "How long does the average home sale take, and what factors can impact the timeline?",
            answer: "The duration of a home sale can vary based on multiple factors, including market conditions, the location and condition of the property, and the pricing strategy. On average, the process typically takes several weeks to a few months from listing to closing. However, it's essential to note that each situation is unique, and unforeseen circumstances can impact the timeline. Rest assured, the NRE team will provide you with a realistic expectation of the selling timeline based on our experience in the local market, keeping you informed and updated every step of the way."
        },
        {
            question: "What can I do to prepare my home for sale and maximize its appeal to potential buyers?",
            answer: "To ensure your home shines in the eyes of potential buyers, there are several steps you can take to prepare it for sale. This may include decluttering and depersonalizing to create a neutral canvas, making any necessary repairs or upgrades to enhance its appeal, and staging the home to showcase its best features. With the guidance of the NRE team, we'll provide you with personalized recommendations tailored to your home's unique attributes, helping you present it in its best possible light and maximize its market value."
        },
        {
            question: "What are the costs associated with selling a home, and how can I estimate my net proceeds?",
            answer: "Selling a home involves various costs beyond the initial listing price, and it's essential to understand these expenses to estimate your net proceeds accurately. Some common costs associated with selling a home include real estate agent commissions, closing costs (such as title insurance, attorney fees, and transfer taxes), home inspection and appraisal fees, and any necessary repairs or improvements to prepare the home for sale. By working closely with the NRE team, we'll provide you with a comprehensive breakdown of these costs upfront, allowing you to plan and budget accordingly."
        },
        {
            question: "How will you market my home to attract potential buyers, and what strategies do you use?",
            answer: "Marketing your home effectively is crucial to attracting potential buyers and maximizing exposure in the market. The NRE team employs a comprehensive marketing strategy that leverages a combination of traditional and digital channels to reach a wide audience of qualified buyers. From professional photography and virtual tours to targeted online advertising and open houses, we'll showcase your home's unique features and benefits to capture the attention of motivated buyers and generate strong interest in your property."
        },
        {
            question: "How will you handle showings, open houses, and inquiries from interested buyers?",
            answer: "Handling showings, open houses, and inquiries from interested buyers can be time-consuming and stressful for sellers, but the NRE team is here to manage these aspects with professionalism and efficiency. We'll coordinate all showings and open houses, ensuring that your home is presented in its best light and that potential buyers have all the information they need to make an informed decision. With our expertise in communication and negotiation, we'll handle inquiries and offers promptly, keeping you informed and involved throughout the process."
        },
        {
            question: "What is your experience in negotiating offers, and how will you ensure I receive the best possible price for my home?",
            answer: "Negotiating offers is a critical aspect of the home selling process, and the NRE team excels in this area. With our strong negotiation skills and market knowledge, we'll advocate on your behalf to secure the best possible price and terms for your home. Whether it's negotiating the purchase price, contingencies, or closing costs, you can trust the NRE team to represent your interests and achieve a successful outcome for your sale."
        },
        {
            question: "What happens if my home doesn't sell quickly or doesn't receive any offers?",
            answer: "In the event that your home doesn't sell quickly or doesn't receive any offers, it's essential to reassess your selling strategy and make any necessary adjustments. The NRE team will work closely with you to evaluate market conditions, analyze buyer feedback, and identify potential obstacles that may be hindering the sale. From adjusting the pricing strategy to enhancing the home's appeal through staging or marketing efforts, we'll collaborate with you to develop a proactive plan to overcome challenges and achieve a successful sale."
        },
        {
            question: "How will you keep me informed throughout the selling process, and what communication can I expect?",
            answer: "Communication is key throughout the home selling process, and the NRE team is committed to keeping you informed every step of the way. We'll provide regular updates on market activity, showing feedback, and any offers or inquiries received, ensuring that you have the information you need to make informed decisions. With open and transparent communication, you can trust that the NRE team has your best interests at heart and is dedicated to achieving your selling goals with clarity and confidence."
        },
        {
            question: "What sets your real estate agency apart from others, and why should I choose you to sell my home?",
            answer: "What sets the NRE team apart from others is our unwavering commitment to client satisfaction and our track record of success. With a reputation for integrity, professionalism, and exceptional service, we've built lasting relationships with our clients based on trust and transparency. Our team of experienced agents is dedicated to providing personalized attention and tailored solutions to meet your unique needs, ensuring a positive and rewarding experience from start to finish."
        },
        {
            question: "What happens after we receive an offer on my home, and what are the next steps?",
            answer: "After receiving an offer on your home, the next steps involve careful consideration and negotiation. Firstly, you'll review the terms of the offer, including the proposed purchase price, contingencies, and any special conditions. With the guidance of your real estate agent, you'll assess the strength of the offer and determine whether it aligns with your goals. If the offer meets your expectations, you may choose to accept it outright or negotiate specific terms to reach a mutually beneficial agreement. Alternatively, if the offer falls short or contains unfavorable conditions, you can counteroffer to revise certain aspects. Throughout this process, your agent will act as your advocate, providing expert advice and facilitating communication between you and the prospective buyer. Once both parties reach an agreement and all terms are finalized, you'll proceed to the next stage of the transaction, which typically involves inspections, appraisals, and finalizing financing arrangements."
        },
    ];

    const handleClick = (index) => {
        const newVisible = [...abAVisible];
        newVisible[index] = !newVisible[index];
        setAbAVisible(newVisible);
    };

    return (
        <>
            <NavbarBlack />
            <div className="as-page-container">
                <h1 className="as-page-headline-h1">HOME SELLER'S GUIDE</h1>
                <p className="as-subheadline">A useful resource for potential home sellers</p>
                <div className="as-img-wrapper">
                    <img src="/luxury-img.jpg" className="as-img" alt="Luxury Home Interior" />
                </div>
                <p className="as-faq">Frequently Asked Questions</p>
                <p className="small-screen-intro">
                    We hope this guide containing the questions most frequently asked by our clients will be a benefit to you as you begin your home selling journey. If you would like a more in-depth consultation, our team of experienced professionals are here to assist you.
                </p>
                <div className="as-main-text-div">
                    {questionsAndAnswers.map((item, index) => (
                        <div key={index}>
                            <p className="as-q">{index + 1}. {item.question}</p>
                            <p className="as-a">{item.answer}</p>
                        </div>
                    ))}
                    <div className="as-button-container">
                        <Link to="/seller-questionnaire" className="as-button">Sell With Nevin</Link>
                    </div>
                </div>
            </div>
            <TestimonialsComponent />
            <div className="as-parallax">
                <div className="as-parallax-content">
                    <h1 className="as-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="as-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutSelling