import React, { useState } from 'react'
import NavbarBlack from '../../components/Navbar/NavbarBlack';
import { Link } from 'react-router-dom';
import '../Testimonials/Testimonials.css';
import { testimonials } from '../Testimonials/Data';
import { FaStar } from 'react-icons/fa';
import Footer from '../../components/Footer/Footer';

function Testimonials() {
    const maxLength = 250;
    const [showFullText, setShowFullText] = useState({});

    const toggleTextDisplay = (index) => {
        setShowFullText({ ...showFullText, [index]: !showFullText[index] });
    };

    return (
        <>
            <NavbarBlack />
            <div className="t-page-container">
                <h1 className="t-page-headline-h1">TESTIMONALS</h1>
                <p className="t-subheadline">What our clients say about working with Nevin Real Estate</p>
                <div className="t-img-wrapper">
                    <img src="/luxury-img.jpg" className="t-img" alt="Luxury Home Interior" />
                </div>
                {testimonials.map((testimonial, index) => (
                    <div className="testimonial">
                        <div className="testimonial-card" key={index}>
                            <div className="testimonial-qm-div">
                                <span className="testimonial-qm">&ldquo;</span>
                            </div>
                            <p className="testimonial-text">
                                {showFullText[index] ? testimonial.testimonial : testimonial.testimonial.slice(0, maxLength)}
                                {testimonial.testimonial.length > maxLength && (
                                    <span className="more-link" onClick={() => toggleTextDisplay(index)}>
                                        {showFullText[index] ? ' Less' : <><span className='dots'>...</span> More</>}
                                    </span>
                                )}
                            </p>
                        </div>
                        <div className="testimonial-details">
                            <p className="testimonial-name">{testimonial.name}, {testimonial.site === 'Zillow' ? (
                                <><Link to="https://www.zillow.com/profile/nevinr/#reviews" className="t-source">{testimonial.site}</Link></>
                            ) : (
                                <><Link to="https://www.google.com/search?q=nevin+real+estate+google+reviews&rlz=1C1_____en-USUS1021US1021&oq=nevin+real&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7Mg0IARAuGK8BGMcBGIAEMgYIAhBFGDkyCAgDEAAYFhgeMggIBBAAGBYYHjIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPdIBCDE0ODZqMGoxqAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x87f62123f0ef4775:0xba21d7b3be8de383,1,,,," className="t-source">{testimonial.site}</Link></>
                            )}
                            </p>
                            {/* <p className="testimonial-date">{testimonial.date}</p> */}
                        </div>
                    </div>
                ))}
            </div>
            <div className="testimonial-parallax">
                <div className="testimonial-parallax-content">
                    <h1 className="testimonial-parallax-heading">Work With Nevin Real Estate</h1>
                    <Link to="/" className="testimonial-parallax-button">Let's Connect</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Testimonials
