import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import HomeAbout from '../../components/HomeAbout/HomeAbout';
import HFL from '../../components/HFL/HFL';
import HomeAwards from '../../components/HomeAwards/HomeAwards';
import HT from '../../components/HT/HT';
import HCV from '../../components/HCV/HCV';
import HSL from '../../components/HSL/HSL';
import NewsletterSignUp from '../../components/NewsletterSignUp/NewsletterSignUp';
import WorkWithNevin from '../../components/WorkWithNevin/WorkWithNevin';
import '../Home/Home.css';
import Footer from '../../components/Footer/Footer';

function Home() {
    return (
        <div>
            <HeroSection />
            <HomeAbout />
            <div className="ha-parallax">
                <HomeAwards />
            </div>
            <HFL />
            <HT />
            <div className="hcv-parallax">
                <HCV />
            </div>
            <HSL />
            <NewsletterSignUp />
            <WorkWithNevin />
            <Footer />
        </div>
    )
}

export default Home
