import React, { useState } from 'react';
import axios from 'axios';
import "../ContactForm/ContactForm.css";
import { FaTimes } from 'react-icons/fa';
import CF from './CF';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: '',
    });
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://82.180.133.122/api/contact', formData);
            setFormData({ firstName: '', lastName: '', phone: '', email: '', message: '' });
            setSuccess(true);
        } catch (err) {
            console.error(err);
            alert('Something went wrong');
        }
    };

    const handleCloseSuccess = () => {
        setSuccess(false);
    };

    return (
        <div className="contact-form-container">
            <CF className="cf" />
            {/* <form onSubmit={handleSubmit}>
                <div className="questionnaire-input">
                    <div className="label-input-wrapper">
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            className="questionnaire-input-field-sm"
                            placeholder="First Name"
                            onChange={handleChange}
                            required />
                    </div>
                </div>
                <div className="questionnaire-input">
                    <div className="label-input-wrapper">
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            className="questionnaire-input-field-sm"
                            placeholder="Last Name"
                            onChange={handleChange}
                            required />
                    </div>
                </div>
                <div className="questionnaire-input">
                    <div className="label-input-wrapper">
                        <input
                            type="number"
                            id="phone"
                            name="phone"
                            className="questionnaire-input-field-sm"
                            placeholder="Phone Number"
                            value={formData.phone}
                            onChange={handleChange}
                            required />
                    </div>
                </div>
                <div className="questionnaire-input">
                    <div className="label-input-wrapper">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="questionnaire-input-field-sm"
                            placeholder="Email Address"
                            value={formData.email}
                            onChange={handleChange}
                            required />
                    </div>
                </div>
                <div className="questionnaire-input">
                    <div className="label-input-wrapper">
                        <textarea
                            id="message"
                            name="message"
                            className="questionnaire-input-field-lg"
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="questionnaire-button-container">
                    <button type="submit" className="questionnaire-submit-button">Submit</button>
                </div>
            </form>
            {success && (
                <div className="questionnaire-success">
                    <div className="questionnaire-fatimes-container">
                        <FaTimes className="questionnaire-success-modal-close" onClick={handleCloseSuccess} />
                    </div>
                    <p className="questionnaire-success-p">
                        Thank you for submitting the contact form!
                    </p>
                    <p className="questionnaire-success-p">
                        A member of our team will be in contact within 1 business day.
                    </p>
                </div>
            )} */}
        </div>
    );
};

export default ContactForm