import React, { useState } from 'react';
import axios from 'axios';
import "../NewsletterSignUp/NewsletterSignUp.css";
import NSUForm from './NSUForm';

function NewsletterSignUp() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://82.180.133.122/api/newsletterSignUp', formData);
            alert('Sign up form submitted successfully');
            setFormData({ firstName: '', lastName: '', email: '' });
        } catch (err) {
            console.error(err);
            alert('Something went wrong');
        }
    };

    return (
        <>
            <div className="nsu-container">
                <h1 className="nsu-h1">Sign Up For Our Newsletter</h1>
                <div className="nsu-form-container">
                    <NSUForm className="nsu-form" />
                    {/* <div className="nsu-asterisk-means">Fields marked with a <span className="nsu-required-label">*</span> are required.</div>
                    <form onSubmit={handleSubmit}>
                        <div className="nsu-input">
                            <div className="nsu-label-input-wrapper">
                                <label htmlFor="firstName" className="nsu-label">First Name<span className="required-label">*</span></label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    className="nsu-input-field-sm"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required />
                            </div>
                        </div>
                        <div className="nsu-input">
                            <div className="nsu-label-input-wrapper">
                                <label htmlFor="lastName" className="nsu-label">Last Name<span className="required-label">*</span></label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    className="nsu-input-field-sm"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required />
                            </div>
                        </div>
                        <div className="nsu-input">
                            <div className="nsu-label-input-wrapper">
                                <label htmlFor="email" className="nsu-label">Email Address<span className="required-label">*</span></label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="nsu-input-field-sm"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required />
                            </div>
                        </div>
                        <div className="nsu-button-container">
                            <button type="submit" className="nsu-submit-button">Submit</button>
                        </div>
                    </form> */}
                </div>
            </div>
        </>
    )
}

export default NewsletterSignUp
